import { createSlice } from "@reduxjs/toolkit";
  
  export const recommendedItemSlice = createSlice({
    name: "recommendedItem",
    initialState: {
        showItems:false
    },
    
    reducers: {
     toggleItem: (state, action) => {
        state.showItems = action.payload;
      },
    },
  });
  
  export const { toggleItem } = recommendedItemSlice.actions;
  export default recommendedItemSlice.reducer;