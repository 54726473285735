import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { aqlutstorage, containerProfile } from "../../Utils/ImageStorage";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 14,
};

export default function AlertModalForCompleteTakeaway({
  open,
  handleClose,
  message,
}) {
  const restaurentData = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
  );
  const userlang = useSelector((state) => state.selectLang.direction);

  let logo_restaurent = aqlutstorage + containerProfile + restaurentData?.logo;

  return (
    <div>
      <Modal open={open}  >
        <Box sx={style}>
          <AlertMessageWrapper>
            <header>
              <img src={logo_restaurent} alt="Logo" />
              <div>
                <p>
                  {userlang === "rtl"
                    ? restaurentData?.ar_name
                    : restaurentData?.name}
                </p>
              </div>
            </header>

            <main>
              <p>{message}</p>
              <button onClick={handleClose} >Close</button>
            </main>
          </AlertMessageWrapper>
        </Box>
      </Modal>
    </div>
  );
}

const AlertMessageWrapper = styled.div`
  width: 100%;
  min-height:100px;
  max-height:100%;
  padding:10px 20px;

  header {
    width: 100%;
    height: 60px;
    display: flex;
    gap: 20px;
    align-items: center;
    border-bottom: 1px solid #979797;

    img {
      width: 44px;
      height: 44px;
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 27px;
      color: #fc2b6e;
    }
  }

  main {
    display:flex;
    flex-direction:column;
    align-items: center;
    padding:10px 0;
     p {
        font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 27px;
      color: #000000;
      padding:10px 0; 
     }

     button {
        width:200px;
    background: #fff2f6;
    border: 1px solid #fc2b6e;
    border-radius: 6.38417px;
    padding: 10px 10px 10px 10px;
    margin: 6px 10px 0px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    color: #fc2b6e;
}
     }
  }
`;
