import { Backdrop, CircularProgress } from "@mui/material";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import { getMaterQrCodeDetails, getrestaurentIdByQrcode } from "../../services/Collection";
import {
  setQrCodeID,
  setQrCodeIDAndRestuarentID,
} from "../../store/MultiUserStore";
import { addRestaurentData } from "../../store/restaurenDetail";
import { checkQrcodeIsMaster } from "../../store/userLoginReducer";
import { BackgroundCover } from "../../Utils/Images";
import {
  aqlutstorage,
  containerMenuBranding,
  containerProfile,
} from "../../Utils/ImageStorage";

export default function MasterQrCode() {

  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);
  const dispatch = useDispatch();
  const [message, setmessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [masterQrDetails, setMasterQrDetails] = useState([]);
  const MenuBranding = useSelector(
    (state) =>
      state?.restaurentSlice?.restaurentData?.Menu?.Restaurent?.MenuPageBranding
  );
  const display_image = MenuBranding?.display_image;
  let BrandingCover = aqlutstorage + containerMenuBranding + display_image;

  async function getMenuItem(payload) {
    setLoading(true);
    setmessage("");
    if (window.navigator.onLine) {
      const EndPoint =
        process.env.REACT_APP_BASEURL + process.env.REACT_APP_API_VERSION;
      const baseURL = `${EndPoint}restaurents/getQrCodeDetails/`;
      var url = `${baseURL}?qr_code_id=${payload}`;

      try {
        const res = await axios({
          method: "get",
          url: url,
          headers: { "Content-Type": "multipart/form-data" },
        });
        dispatch(addRestaurentData(res?.data?.data));
        dispatch(checkQrcodeIsMaster(res?.data?.data?.is_master));
        if (res?.data?.data?.is_master) {
          getDataFromIsMaster(payload);
        }
      } catch (response_1) {
        let msg =
          "Menu or  sections not found for Restaurents please try with another QR code.";
        setMasterQrDetails([]);
        setmessage(response_1?.response?.data?.message || msg);
        setLoading(false);
      }
    } else {
      let msg = "Please check your internet connection.";
      setmessage(msg);
      setLoading(false);
    }
  }

  const getDataFromIsMaster = async (payload) => {
    // if (location?.search.includes(payload) || location?.pathname.includes(payload)) {
    let res = await getMaterQrCodeDetails(payload);
    if (res.status === 200) {
      setMasterQrDetails(res.data);
      setmessage("");
      setLoading(false);
    } else {
      setMasterQrDetails([]);
      setmessage(res?.response?.data?.message);
      setLoading(false);
    }
    // }
  };

  const setQrAndResValuetoStore = (payload) => {
    if (getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.length > 0) {
      const updatedArray =
        getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.filter(
          (item) => Number(item.restaurentid) !== Number(payload.restaurentid)
        );
      updatedArray.push(payload);
      if (updatedArray) dispatch(setQrCodeIDAndRestuarentID(updatedArray));
    } else {
      if (payload) dispatch(setQrCodeIDAndRestuarentID([payload]));
    }
  };


  const getRestuarantid = async (id) => {
    let res = await getrestaurentIdByQrcode(id);
    if (res.status === 200) {
      let parameters = {
        qrcodeid: res.data.id,
        restaurentid: res.data.restaurent_id,
      };
      setQrAndResValuetoStore(parameters);
      dispatch(checkQrcodeIsMaster(false));
      navigate(
        `/?qrcodeid=${id}`
      );
      // getMenuItem(id);
    } else {
      toast.error(res.message, { theme: "colored" });
    }
  };

  const changeURl = (id) => {
    getRestuarantid(id);
    // getMenuItem(id);
  };

  useEffect(() => {
    getMenuItem(currentActive?.[0]?.qrcodeid);
  }, [currentActive?.[0]?.qrcodeid]);

  if (loading) {
    return (
      <Backdrop
        sx={{
          color: "#FC2B6E",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <div style={{ display: "block", textAlign: "center" }}>
          <CircularProgress color="inherit" />
          <p style={{ marginTop: "5px" }}>Processing...</p>
        </div>
      </Backdrop>
    );
  }

  return (
    <IsMasterWrapper background_image={BrandingCover}>
      <BlackLayer />

      {message ? (
        <MasterQr>
          <section className="MasterNotFound">
            <h1>{message}</h1>
          </section>
        </MasterQr>
      ) : (
        <MasterQr>
          <h1>
            {masterQrDetails.length >= 0 &&
              masterQrDetails?.[0]?.MasterQrCode?.Restaurent?.name}
          </h1>

          <h1>
            {masterQrDetails.length >= 0 &&
              masterQrDetails?.[0]?.MasterQrCode?.QrCodeGroup?.group_name}{" "}
            {masterQrDetails.length >= 0 &&
              masterQrDetails?.[0]?.MasterQrCode?.name}
          </h1>
          <div className="MasterQrCard">
            {masterQrDetails?.length > 0 &&
              masterQrDetails?.map((card) => (
                <div
                  className="QRCard"
                  onClick={() => changeURl(card?.child_qr_code_id)}
                >
                  <img
                    src={
                      aqlutstorage +
                      containerProfile +
                      card?.ChildQrCode?.Restaurent?.logo
                    }
                    alt="Logo"
                  />
                  <header>{card?.ChildQrCode?.Restaurent?.name}</header>
                  {/* <p>{card?.ChildQrCode?.QrCodeGroup?.group_name}</p>
                  <h2>{card?.ChildQrCode?.name}</h2> */}
                </div>
              ))}
          </div>
        </MasterQr>
      )}
    </IsMasterWrapper>
  );
}

const MasterQr = styled.div`
  position: relative;
  z-index: 11;
  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 43px;
    color: #ffffff;
    text-align: center;
  }

  .MasterQrCard {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 30px 20px;

    @media (max-width: 500px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .QRCard {
      width: 100%;
      height: 100%;
      background: rgb(0 0 0 / 80%);
      border: 1px solid #000;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 120px;
        height: 120px;
        border-radius: 100%;
        object-fit: contain;
        border: 1px solid #9797978c;
      }

      header {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 35px;
        line-height: 42px;
        color: #ffffff;
        padding: 20px 0;
        text-align: center;
      }

      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 32px;
        color: #ffffff;
      }

      h2 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 40px;
        color: #ffffff;
      }
    }
  }

  .MasterNotFound {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 60px;
    color: #202020;
  }
`;

const BlackLayer = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.5;
  background: #000;
  z-index: 2;
`;

const IsMasterWrapper = styled.div`
  position: relative;
  background: ${({ background_image }) =>
    `${
      background_image !== undefined
        ? `url(${background_image})`
        : `url(${BackgroundCover})`
    }`};
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 20px 10px;
  z-index: 1;

  background-repeat-x: repeat;
`;
