import { Box, SwipeableDrawer } from "@mui/material";
import React from "react";
import styled from "styled-components";
import CloseIcon from "../../assets/close.png";

export default function AllMenuListing({
  id,
  open,
  anchorEl,
  direction,
  styleMenuBox,
  menu,
  handleMenuClick,
}) {
  return (
    <div>
      <SwipeableDrawer
        anchor={"bottom"}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleMenuClick}
        dir={direction}
        style={{ borderRadius: "20px" }}
      >
        <MenuBoxWrapper style={styleMenuBox}>
          <div className="Popup-title">
            <h3>Menu</h3>
            <img alt="" src={CloseIcon} onClick={handleMenuClick} />
          </div>
          <Box className="menuBox">
            {menu?.map((section, key) => {
              return (
                <>
                  {section?.Items?.length > 0 && (
                    <div
                      key={key}
                      className="InnderMenuBox"
                      onClick={() => handleMenuClick(section.id)}
                    >
                      <label key={key + 1}>
                        {direction === "ltr"
                          ? section.section_name
                          : section.ar_section_name}
                      </label>
                      <span key={key + 2}>{section?.Items?.length}</span>
                    </div>
                  )}
                </>
              );
            })}
          </Box>
        </MenuBoxWrapper>
      </SwipeableDrawer>
    </div>
  );
}

const MenuBoxWrapper = styled.div`
  width: 100%;
  .menuBox {
    border-radius: 20px;
    ::-webkit-scrollbar {
      width: 2px;
      bodrer-radius: 50px !important;
    }
    ::-webkit-scrollbar-thumb {
      width: 2px;
      border-radius: 40px;
      background: #ffff;
    }
  }
  .Popup-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 6px;
    img {
      margin: 0 20px;
      margin-top: 5px;
      margin-bottom: 3px;
      height: 13px;
    }
    h3 {
      padding: 0 15px;
    }
  }
`;
