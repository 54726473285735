import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { NotFound } from "../Utils/Images";
import { useLottie } from "lottie-react";
import notFound from "../lotties/notFound.json";

export default function PageNotFound() {
  const navigate = useNavigate();
  const restaurentData = useSelector(
    (state) => state?.restaurentSlice?.restaurentData
  );

  const handleBackNavigate = () => {
    navigate(`/?qrcodeid=${restaurentData?.id}`);
  };
  const options = {
    animationData: notFound,
    loop: true,
  };
  const { View } = useLottie(options);

  return (
    <NotFoundPageWrapper bg={NotFound}>
      <div className="error">{View}</div>
      <button width="80px" onClick={handleBackNavigate}>
        Home Page
      </button>
    </NotFoundPageWrapper>
  );
}

const NotFoundPageWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  flex-direction: column;
  overflow: auto;
  color: black;
  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;

  .error {
    max-width: 300px;
    height: 300px;
  }
  .not-found {
    font-size: 40px;
    margin-bottom: 20px;
  }
  .content {
    width: 482px;
    text-align: center;
    font-family: "circular";
    font-size: 20px;
  }
  button {
    width: 280px;
    height: 45px;
    padding: 14px 30px;
    border-radius: 8px;
    border: 1px solid white;
    background: #66433f;
    color: white;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.5s;
    :hover {
      transform: scale(1.1);
    }
  }
`;
