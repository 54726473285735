import { createSlice } from "@reduxjs/toolkit";

export const orderInformationSlice = createSlice({
  name: "orderPayment",
  initialState: {
    paymentSuccess: false,
    orderDeails: null,
    multiOrderDetails: [],
    splitBill: null,
    PosSyncedOrder: null,
    tip: {
      isCustomOpen: false,
      percentage: 0,
      tip: 0,
      type: 1,
    },
    serviceCharge: {
      serviceAmount : 0,
      splitServiceAmount: 0,
    },
    payBillType: "PayFullBill",
    lastPaymentMode: "",
  },

  //This is SelectLang reducers.

  reducers: {
    paymentSuccess: (state, action) => {
      state.paymentSuccess = action.payload;
    },
    addOrderDetails: (state, action) => {
      state.orderDeails = null;
      if (action.payload) {
        state.orderDeails = action.payload;
      }
    },
    multiOrderDeatils: (state, action) => {
      if (action.payload) {
        state.multiOrderDetails = action.payload;
      }
    },
    splitBill: (state, action) => {
      state.splitBill = action?.payload;
    },
    updatedOrderFromPos: (state, action) => {
      if (action.payload) {
        state.PosSyncedOrder = action.payload;
      }
    },
    updateTip: (state, action) => {
      state.tip = action?.payload;
    },
    wayToPayBillType: (state, action) => {
      state.payBillType = action?.payload;
    },
    updatelastPaymentMode:(state,action)=>{
      state.lastPaymentMode = action?.payload;
    },
    updateServiceCharge:(state,action)=>{
      state.serviceCharge = action?.payload;
    },
  },
});

export const {
  paymentSuccess,
  addOrderDetails,
  multiOrderDeatils,
  splitBill,
  updatedOrderFromPos,
  updateTip,
  wayToPayBillType,
  updatelastPaymentMode,
  updateServiceCharge
} = orderInformationSlice.actions;
export default orderInformationSlice.reducer;
