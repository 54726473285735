import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { CustomTipPencilIcon, InfoIcon } from "../../Utils/Icons";
import { SnapayGrayBackground } from "../../Utils/Images";
import { ThemeButton } from "../../style/SnapayGobalStyle";
import { updateTip } from "../../store/orderPayment";
import { toast } from "react-toastify";
import IntlMessage from "../../Utils/IntlMessage";
import { useIntl } from "react-intl";
import CancelIcon from '@mui/icons-material/Cancel';

const AddTipToBill = () => {
  const customTheme = useSelector((state) => state?.theme?.branding);
  const tipDetails = useSelector((state) => state?.orderInformationSlice?.tip);
  const [selectedTip, setSelectedTip] = useState(tipDetails);
  const [customTip, setCustomTip] = useState(null);
  const dispatch = useDispatch();
  const OrderDetails = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );
  const PayBillType = useSelector(
    (state) => state?.orderInformationSlice?.payBillType
  );
  const splitDetails = useSelector(
    (state) => state?.orderInformationSlice?.splitBill
  );
  const restaurantDetails = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
  );
  const intl = useIntl();

  console.log("tip --", tipDetails);
  console.log(restaurantDetails?.enable_default_tip, "saldkhsja");

  const totalAmount = () => {
    if (PayBillType === "PayFullBill") {
      return OrderDetails?.total;
    }
    if (PayBillType === "SplitTheBill") {
      return splitDetails?.total;
    }
  };

  const handleSelectedTip = (val, mode) => {
    setSelectedTip(val);
    if (mode === "selectedCustomTip") {
      if (isNaN(val?.percentage)) {
        toast.warning("please enter");
        return;
      }
      let result = val?.percentage;
      dispatch(updateTip({ tip: Number(result), ...val }));
    }
    if (val?.type !== 4) {
      let result = CalculateTip(totalAmount(), val?.percentage);
      dispatch(updateTip({ tip: Number(result), ...val }));
    }
  };

  const handleCustom = (custom) => {
    handleSelectedTip(custom, "selectedCustomTip");
  };

  const CalculateTip = (amount, percentage) => {
    let result = (amount * percentage) / 100;
    return result.toFixed(2);
  };

  const handleCrossTip = (e) => {
    e.stopPropagation();
    dispatch(updateTip(null));
    setSelectedTip(null);
  };

  useMemo(() => {
    if (selectedTip && splitDetails) {
      let obj = {
        type: selectedTip?.type,
        percentage: selectedTip?.percentage,
      };
      if (selectedTip?.type !== 4) {
        handleSelectedTip(obj);
      } else {
        handleSelectedTip(obj, "selectedCustomTip");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splitDetails]);


  useEffect(() => {
    if (restaurantDetails?.enable_default_tip && OrderDetails?.total && tipDetails?.percentage == 0) {
      let tip = CalculateTip(OrderDetails?.total, 5);
      dispatch(
        updateTip({ type: 1, percentage: 5, tip: tip, isCustomOpen: false })
      );
    }
  }, [OrderDetails?.total]);

  if (OrderDetails === null) {
    return (
      <p>
        <IntlMessage id="Snapay.Loading" />
      </p>
    );
  }

  return (
    <AddTipToBillStyle
      textColor={customTheme?.text_color}
      background={SnapayGrayBackground}
      themeColor={customTheme?.theme_color}
      button_text_color={customTheme?.button_text_color}
      button_primary_color={customTheme?.button_primary_color}
    >
      <div className="heading">
        <IntlMessage id="Snapay.TipHeading" />
      </div>
      <div className="heading3">
        <InfoIcon />
        <IntlMessage id="Snapay.TipNote" />
      </div>
      {console.log(selectedTip, "selectedTip")}
      <div className="tipWrapper">
        {restaurantDetails?.tip_selector ? (
          <div
            className={`tipBox ${selectedTip?.type === 1 ? "activeTip" : ""}`}
            onClick={() =>
              handleSelectedTip({ type: 1, percentage: 5, isCustomOpen: false })
            }
          >
            {selectedTip?.type === 1 && (
              <span className="cross" onClick={(e) => handleCrossTip(e)}>
                <CancelIcon />
              </span>
            )}
            <span>5%</span>
            <span>{CalculateTip(totalAmount(), 5)}</span>
          </div>
        ) : (
          <div
            className={`tipBox ${selectedTip?.type === 1 && "activeTip"}`}
            onClick={() =>
              handleSelectedTip({
                type: 1,
                percentage: 0,
                tip: 0,
                isCustomOpen: false,
              })
            }
          >
            {selectedTip?.type === 1 && (
              <span className="cross" onClick={(e) => handleCrossTip(e)}>
                 <CancelIcon />
              </span>
            )}
            <span>No Tip</span>
          </div>
        )}

        {/* <div
          className={`tipBox ${selectedTip?.type === 1 && "activeTip"}`}
          onClick={() =>
            handleSelectedTip({ type: 1, percentage: 0, isCustomOpen: false })
          }
        >
          <span style={{fontSize:"15px",fontWeight:"500"}}>No Tip</span>
        </div> */}

        <div
          className={`tipBox ${selectedTip?.type === 2 && "activeTip"}`}
          onClick={() =>
            handleSelectedTip({ type: 2, percentage: 10, isCustomOpen: false })
          }
        >
          {selectedTip?.type === 2 && (
            <span className="cross" onClick={(e) => handleCrossTip(e)}>
         <CancelIcon />
            </span>
          )}
          <span>10%</span>
          <span>{CalculateTip(totalAmount(), 10)}</span>
        </div>
        <div
          className={`tipBox ${selectedTip?.type === 3 && "activeTip"}`}
          onClick={() =>
            handleSelectedTip({ type: 3, percentage: 15, isCustomOpen: false })
          }
        >
          {selectedTip?.type === 3 && (
            <span className="cross" onClick={(e) => handleCrossTip(e)}>
            <CancelIcon />
            </span>
          )}
          <span>15%</span>
          <span>{CalculateTip(totalAmount(), 15)}</span>
        </div>
        <div
          className={`tipBox ${selectedTip?.type === 4 && "activeTip"}`}
          onClick={() =>
            handleSelectedTip({ type: 4, percentage: 0, isCustomOpen: true })
          }
        >
          {selectedTip?.type === 4 && selectedTip?.percentage ? (
            <span style={{ fontSize: "12px", textAlign: "center" }}>
              {(selectedTip?.percentage).toFixed(2)} QAR
            </span>
          ) : (
            <span>
              <CustomTipPencilIcon />
            </span>
          )}
          <span>
            <IntlMessage id="Snapay.TipCustom" />
          </span>
        </div>
      </div>
      {selectedTip?.isCustomOpen && (
        <CustomWrapper>
          <input
            type="number"
            placeholder={intl.formatMessage({
              id: "Snapay.TipPlaceholder",
            })}
            onChange={(e) =>
              setCustomTip({ type: 4, percentage: Number(e.target.value) })
            }
          />
          <ThemeButton
            button_text_color={customTheme?.button_text_color}
            button_primary_color={customTheme?.button_primary_color}
            onClick={() => handleCustom(customTip)}
          >
            <IntlMessage id="Snapay.TipSelect" />
          </ThemeButton>
        </CustomWrapper>
      )}
      {<div className="heading2">
        <IntlMessage id="Snapay.TipInQar" />
      </div>}
    </AddTipToBillStyle>
  );
};

export default AddTipToBill;

const CustomWrapper = styled.div`
  display: flex;
  gap: 10px;
  input {
    width: 100%;
    padding: 14px 13px;
    border-radius: 6px;
    border: 1px solid #5c5c5c;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    color: #646464;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin: 10px 0 30px 0;
  }

  button {
    width: 50%;

    margin: 10px 0 30px 0;
  }
`;

const AddTipToBillStyle = styled.div`
  padding: 20px 16px;
  border-radius: 8px;
  background: ${({ background }) => `url(${background})`};
  background-size: contain;

  .heading {
    color: ${({ textColor }) => textColor};
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.42px;
  }
  .heading2 {
    color: ${({ textColor }) => textColor};
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.42px;
  }
  .tipWrapper {
    margin: 15px 0 10px 0;
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .tipBox {
      width: 100%;
      height: 64px;
      border-radius: 4px;
      border: ${({ themeColor }) => `1px solid ${themeColor}`};
      display: flex;
      gap: 7px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: ${({ textColor }) => textColor};
      position: relative;

      .cross {
        position: absolute;
        right: -5px;
        top: -8px;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        color: white;
        justify-content: center;
        align-items: baseline;
        display: flex;
        cursor: pointer;
        background:#66433F;
      }

      span:first-child {
        font-family: "DM Sans", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.48px;
      }
      span:last-child {
        font-family: "DM Sans", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.36px;
      }
    }
    .activeTip {
      color: ${({ button_text_color }) => button_text_color};
      background: ${({ button_primary_color }) => button_primary_color};
    }
  }
  .heading3 {
    color: ${({ textColor }) => textColor};
    font-family: "DM Sans", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.3px;
    background: #a87c7766;
    border-radius: 4px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 10px;
  }
`;
