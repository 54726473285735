import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  addRecommendedItem,
  deleteRecommendedItem,
} from "../../store/selectItem";
import "../../style/Recommendeditem.scss";
import { aqlutstorage, containerItem } from "../../Utils/ImageStorage";
import IntlMessage from "../../Utils/IntlMessage";

const RecommendedItem = ({ onClose }) => {
  const path = window.location.pathname;
  const { direction, item, recommendedItems } = useSelector(
    ({ selectLang, selectItemSlice }) => ({ ...selectLang, ...selectItemSlice })
  );
  const dispatch = useDispatch();
  // const POSisEnable = useSelector((state) => state?.posSlice?.isEnable);
  const POSisEnable = false;
  const customTheme = useSelector((state) => state?.theme?.branding);

  const handleAddRecommededItem = (id, price) => {
    const obj = {
      id,
      price,
    };

    dispatch(addRecommendedItem(obj));
  };

  const handleDeleteRecommededItem = (id) => {
    dispatch(deleteRecommendedItem(id));
  };
  const findData = (id) => {
    const arr = recommendedItems.filter((v) => v.id === id && v.count > 0);
    return arr?.[0]?.count;
  };

  return (
    <div className="ItemContainer" dir={direction}>
      <div className="FoodDetailsCntainers">
        <div className="RecoomandDetailsWrappers">
          {path === "/cartwithdiscount" ||
          path === "/cart" ||
          path === "/cartwithoutdiscount" ? (
            <p>
              <IntlMessage id="cart.recommended" /> (6)
            </p>
          ) : direction === "ltr" ? (
            <p style={{ color: customTheme?.header_color }}>
              <IntlMessage id="AddItemDrawer.recommendeditems" />
            </p>
          ) : (
            <p> العناصر الموصى بها</p>
          )}

          {path === "/cartwithdiscount" ||
          path === "/cart" ||
          path === "/cartwithoutdiscount" ? ( //custom route define restrict for specific route
            <img
              onClick={onClose}
              src={require("../../assets/close.png")}
              style={{ display: "none" }}
              alt="close-button-icon"
              className="ReCloseIcon"
            />
          ) : (
            <img
              onClick={onClose}
              src={require("../../assets/close.png")}
              alt="close-button-icon"
              className="ReCloseIcon"
              style={{ display: "block" }}
            />
          )}
        </div>

        <div className="RecommandMainContainer">
          {item?.RecommendedItems?.map((sItem, key) => {
            return (
              <>
                <RecommendInnerContainer
                  boxBackground={customTheme?.placeholder_box_color}
                >
                  <div className="recommendIndivaualSection">
                    <div className="left-section">
                      <img
                        src={aqlutstorage + containerItem + sItem?.Item?.image}
                        alt="food-img"
                      />
                    </div>

                    <div className="right-section">
                      <div className="name-price-section">
                        <p>
                          {direction === "ltr"
                            ? sItem?.Item?.name || ""
                            : sItem?.Item?.ar_name || ""}
                        </p>
                        <p>
                          <h5 style={{ color: customTheme?.theme_color }}>
                            QAR{" "}
                            {POSisEnable
                              ? sItem?.Item?.ItemPrices[0].pos_item_price
                              : sItem?.Item?.ItemPrices[0].price}
                          </h5>
                        </p>
                      </div>
                    </div>
                  </div>
                </RecommendInnerContainer>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { RecommendedItem };

const RecommendInnerContainer = styled.div`
  margin: 10px 4px;
  .recommendIndivaualSection {
    width: 320px;
    height: 108px;
    padding: 10px;
    display: flex;
    gap: 8px;
    border-radius: 8px;
    box-shadow: 0 3px 8px 4px rgb(0 0 0 / 15%);
    background: ${({ boxBackground }) => boxBackground};

    .left-section {
      width: 30%;
      height: 100%;
      border-radius: 8px;
      border: 1px solid rgb(0 0 0 / 8%);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }

    .right-section {
      width: 70%;
      height: 100%;
      // background: red;
      display: flex;
      justify-content: space-between;

      .name-price-section {
        p {
          font-family: "Nunito Sans", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 157%;
          margin: 0;
          color: #858992;
        }
        h5 {
          font-family: "Nunito Sans", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 157%;
          margin: 0;
          color: #858992;
        }
      }

      .recommendedAddtocart {
        display: flex;
        align-items: end;
      }
    }
  }
`;

const QuantityMainWrapper = styled.div`
  display: flex;
  width: 62px;
  height: 26px;
  justify-content: space-evenly;
  // background-color: white;
  background: #ffffff;
  border: 1px solid rgba(203, 0, 52, 1);
  border-radius: 30px;

  button {
    border: none;
    background: none;
    color: #fc2b6e;
    font-size: 18px;
  }
  button:hover {
    cursor: pointer;
  }
  p {
    color: #202020;
    ${"" /* margin: 5px; */}
    margin-top: 2px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
  }
`;
