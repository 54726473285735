import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  splitBill,
  updateServiceCharge,
  wayToPayBillType,
} from "../../store/orderPayment";
import { ThemeButton } from "../../style/SnapayGobalStyle";
import {
  addCommaToNumbers,
  calculateServiceCharge,
} from "../../Utils/CommanFunctions";
import { CrossDrawerIcon } from "../../Utils/Icons";
import IntlMessage from "../../Utils/IntlMessage";
import Items from "./Items";

const PayForYourItem = ({ Close }) => {
  const customTheme = useSelector((state) => state?.theme?.branding);
  const [orderArr, setOrderArr] = useState([]);
  const [yourShare, setYourShare] = useState(null);
  const dispatch = useDispatch();
  const order = useSelector(
    (state) => state?.orderInformationSlice?.PosSyncedOrder
  );
  const splitBillPayload = useSelector(
    (state) => state?.orderInformationSlice?.splitBill
  );
  let initialSelectedItem =
    splitBillPayload?.selectedItems?.length > 0
      ? splitBillPayload?.selectedItems
      : [];
  const [selectedItems, setSelectedItems] = useState(initialSelectedItem);
  const langDir = useSelector((state) => state?.selectLang);
  const serviceDetails = useSelector(
    (state) => state?.orderInformationSlice?.serviceCharge
  );
  const restaurentDetails = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
  );

  const handleChecked = (val) => {
    console.log(val, "valllllllllllllllllllllllllll");
    if (
      selectedItems &&
      selectedItems?.length > 0 &&
      selectedItems?.some((el) => el?.id === val?.id)
    ) {
      let updatedArray = selectedItems?.filter((ele) => ele?.id !== val?.id);
      if (
        selectedItems?.some(
          (ele) =>
            ele?.id === val?.id && ele?.customQuantity !== val?.customQuantity
        )
      ) {
        setSelectedItems([...updatedArray, val]);
      } else {
        setSelectedItems(updatedArray);
      }
    } else {
      setSelectedItems([...selectedItems, val]);
    }
  };

  const handleNewOrderArr = () => {
    if (
      splitBillPayload?.selectedItems &&
      splitBillPayload?.selectedItems?.length > 0
    ) {
      let tempArray = [];
      let flag = true;
      for (let index = 0; index < order?.posOrderItems.length; index++) {
        for (let j = 0; j < splitBillPayload?.selectedItems.length; j++) {
          if (
            order?.posOrderItems[index]?.id ===
            splitBillPayload?.selectedItems[j]?.id
          ) {
            tempArray.push({
              ...splitBillPayload?.selectedItems[j],
              ischecked: true,
            });
            flag = false;
          }
        }
        flag &&
          tempArray.push({ ...order?.posOrderItems[index], ischecked: false });
        flag = true;
      }
      setOrderArr(tempArray);
    } else {
      setOrderArr(order?.posOrderItems);
    }
  };

  const calculateItemPrice = (payload) => {
    console.log(payload, "payloadddddddcalculateeeeeeeeeeeeeeee");
    let tempPrice = 0;
    if (payload?.posOrderItemAddOns?.length > 0) {
      if (payload?.paid_quantity === 0) {
        for (
          let index = 0;
          index < payload?.posOrderItemAddOns?.length;
          index++
        ) {
          tempPrice =
            tempPrice + Number(payload?.posOrderItemAddOns[index]?.price);
        }
        tempPrice = tempPrice + payload?.amount;
      } else {
        for (
          let index = 0;
          index < payload?.posOrderItemAddOns?.length;
          index++
        ) {
          tempPrice =
            tempPrice + Number(payload?.posOrderItemAddOns[index]?.price);
        }
        let unpaidQty = payload?.quantity - payload?.paid_quantity;
        tempPrice = (payload?.amount / payload?.quantity) * unpaidQty;
      }
    } else {
      if (payload?.paid_quantity === 0) {
        tempPrice = payload?.amount;
      } else {
        let unpaidQty = payload?.quantity - payload?.paid_quantity;
        tempPrice = (payload?.amount / payload?.quantity) * unpaidQty;
      }
    }
    return tempPrice;
  };
  const calculateYourShare = (payload) => {
    let tempPrice = 0;
    let totalPrice = 0;
    for (let j = 0; j < payload?.length; j++) {
      if (payload?.[j]?.posOrderItemAddOns?.length > 0) {
        for (
          let index = 0;
          index < payload?.[j]?.posOrderItemAddOns?.length;
          index++
        ) {
          tempPrice =
            tempPrice + Number(payload?.[j]?.posOrderItemAddOns[index]?.price);
        }
        tempPrice = tempPrice + payload?.[j]?.amount;
      } else {
        tempPrice = payload?.[j]?.amount;
      }
      totalPrice +=
        (tempPrice / payload?.[j]?.quantity) * payload?.[j]?.customQuantity;
      tempPrice = 0;
    }
    return totalPrice;
    // return addCommaToNumbers(totalPrice);
  };

  let obj = {
    type: "PayforYourItem",
    selectedItems: selectedItems,
    total: calculateYourShare(selectedItems),
  };

  let dataObj = {
    totalServiceAmount: serviceDetails?.totalServiceAmount,
    serviceAmount: serviceDetails?.serviceAmount,
    splitServiceAmount: calculateServiceCharge(
      calculateYourShare(selectedItems),
      restaurentDetails?.our_service_charge
    ),
  };

  useEffect(() => {
    handleNewOrderArr();
  }, []);

  return (
    <PayForYourItemStyle
      textColor={customTheme?.text_color}
      themeColor={customTheme?.theme_color}
      dir={langDir?.direction}
    >
      <div className="heading">
        <span>
          <IntlMessage id="Snapay.PayForYourItemsChooseYourItems" />
        </span>
        <i onClick={Close}>
          <CrossDrawerIcon />
        </i>
      </div>

      <div className="item">
        {orderArr?.map((val, index) => {
          return (
            <Items
              handleChecked={handleChecked}
              calculateItemPrice={calculateItemPrice}
              val={val}
              key={index}
            />
          );
        })}
      </div>
      <hr></hr>
      <div className="totalComman total">
        <span>
          <IntlMessage id="Snapay.DivideEquallyTotal" />
        </span>
        <span>
          <IntlMessage id="Snapay.QAR" />{" "}
          {addCommaToNumbers(order?.total, serviceDetails?.serviceAmount)}
        </span>
      </div>
      <div className="totalComman yourShare">
        <span>
          <IntlMessage id="Snapay.DivideEquallyYourShare" />
        </span>
        <span>
          <IntlMessage id="Snapay.QAR" />{" "}
          {(selectedItems.length > 0 &&
            addCommaToNumbers(
              obj?.total,
              calculateServiceCharge(
                obj?.total,
                restaurentDetails?.our_service_charge
              )
            )) ||
            "0.00"}
        </span>
      </div>

      <ThemeButton
        button_text_color={customTheme?.button_text_color}
        button_primary_color={customTheme?.button_primary_color}
        onClick={() => {
          Close();
          dispatch(splitBill(obj));
          dispatch(wayToPayBillType("SplitTheBill"));
          dispatch(updateServiceCharge(dataObj));
        }}
      >
        <IntlMessage id="Snapay.DivideEquallyConfirm" />
      </ThemeButton>
    </PayForYourItemStyle>
  );
};

export default PayForYourItem;

const PayForYourItemStyle = styled.div`
  padding: 24px 24px 30px;

  input[type="checkbox"] {
    accent-color: ${({ themeColor }) => themeColor};
  }

  .heading {
    color: ${({ textColor }) => textColor};
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.42px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    i {
      cursor: pointer;
    }
  }

  .item {
    margin: 20px 0 30px;
  }
  .itemsWrapper {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      gap: 16px;
    }
  }

  .AddBtn {
    display: flex;
    align-items: center;
    gap: 5px;

    .count {
      color: ${({ textColor }) => textColor};
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.42px;
    }
    .icon {
      background: ${({ themeColor }) => themeColor};
      width: 17px;
      height: 17px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .defaultIcon {
      background: #acacac;
    }
  }

  .itemDetails {
    width: 100%;
    color: ${({ textColor }) => textColor};
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .amount {
    width: 75px;
    color: ${({ textColor }) => textColor};
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.36px;
  }
  .totalComman {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .total {
    color: #646464;
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 14px 0 10px 0;
  }
  .yourShare {
    color: ${({ textColor }) => textColor};
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 40px;
  }
`;
