import { createSlice } from "@reduxjs/toolkit";

export const deliverySlice = createSlice({
  name: "deliveryModule",

  initialState: {
    isDeliveryActive: false,
    orderType: "takeaway",
    payment_method: 1,
    selectedAddress: null
  },

  reducers: {
    swicthingMode: (state, action) => {
      state.isDeliveryActive = action.payload;
    },
    isTakeawayDevliveryOrderType: (state, action) => {
      if (action?.payload) {
        state.orderType = action.payload;
      }
    },
    updatePaymentMethod: (state, action) => {
      if (action?.payload) {
        state.payment_method = action?.payload;
      }
    },
    updateAddress: (state, action) => {
      if (action?.payload) {
        state.selectedAddress = action?.payload;
      }
    }
  },
});

export const {
  swicthingMode,
  isTakeawayDevliveryOrderType,
  updatePaymentMethod,
  updateAddress
} = deliverySlice.actions;

export default deliverySlice.reducer;
