import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SnapayLogo } from "../../Utils/Images";
import {
  ArrowLeftIcon,
  Humburger,
  LaunchGlobe,
  LaunchMenuIcon,
  LaunchPayBillIcon,
  PaybillLoader,
  PaybillRefresh,
  RightArrow,
  SnapaySLogo,
} from "../../Utils/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  PosSyncStatus,
  getCheckDetails,
  getUpdateOrderDetails,
  syncOrderPayemnt,
  syncPOS,
  RecreateExistingOrderApi,
} from "../../services/Collection";
import {
  addOrderDetails,
  updateServiceCharge,
  updateTip,
} from "../../store/orderPayment";
import { aqlutstorage, containerProfile } from "../../Utils/ImageStorage";
import {
  addCommaToNumbers,
  calculateServiceCharge,
} from "../../Utils/CommanFunctions";
import { langSuccess } from "../../store/selectlang";
import IntlMessage from "../../Utils/IntlMessage";
import Sidenav from "../../components/Sidenav/Sidenav";

export default function Launch() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(null);
  const [message, setMessage] = useState("");
  const [isPOSEnable, setIsPOSEnable] = useState(true);
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);
  const [loading, setLoading] = useState(true);
  const OrderDetails = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );
  const name = window?.location?.search?.substring(1)?.split("=")?.[0];
  const restaurantDetails = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
  );
  const Restaurentinfo = useSelector(
    (state) => state?.restaurentSlice?.restuarentProfileInfo
  );
  let SnapayLaunchBackground =
    aqlutstorage + containerProfile + Restaurentinfo?.background_landing_page;
  const handleMenuButton = (active) => {
    setActiveTab(active);
    setTimeout(() => {
      navigate(
        `/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/${active}`
      );
    }, 500);
  };

  const location = useLocation();
  const logo_restaurent =
    aqlutstorage + containerProfile + Restaurentinfo?.logo;

  const langDir = useSelector((state) => state?.selectLang);
  const serviceDetails = useSelector(
    (state) => state?.orderInformationSlice?.serviceCharge
  );
  const [openSidenav, setOpenSidenav] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleSelectedLanguage = (lang) => {
    if (lang === "en") {
      let obj = {
        lang: "en",
        direction: "ltr",
      };
      dispatch(langSuccess(obj));
    } else if (lang === "ar") {
      let obj = {
        lang: "ar",
        direction: "rtl",
      };
      dispatch(langSuccess(obj));
    }
  };

  const updateOrderDetails = async (qrcode) => {
    let payload = {
      order_id: null,
      qr_code_id: qrcode,
    };

    let res = await getUpdateOrderDetails(payload);
    if (res.status === 200) {
      dispatch(addOrderDetails(res?.data));
      if (restaurantDetails?.our_service_charge > 0) {
        const amount = calculateServiceCharge(
          res?.data?.total,
          restaurantDetails?.our_service_charge
        );
        let obj = {
          totalServiceAmount: amount,
          serviceAmount: amount,
          splitServiceAmount: amount,
        };
        dispatch(updateServiceCharge(obj));
      }

      const payload = {
        restaurent_id: currentActive?.[0]?.restaurentid,
        qr_code_id: currentActive?.[0]?.qrcodeid,
      };

      let recreateRes = await RecreateExistingOrderApi(payload);

      if (recreateRes?.status === 200 && recreateRes?.data) {
        dispatch(addOrderDetails(null));
        chekcingOrderDetails(
          currentActive?.[0]?.restaurentid,
          currentActive?.[0]?.qrcodeid
        );
      }
      setLoading(false);
    } else {
      dispatch(addOrderDetails(null));
      // toast.error(res.error || res.response.data.message, { theme: "colored" });
      setLoading(false);
    }
  };
  const syncOrderPaymentinDB = async (qrcode, restaurantid) => {
    let req = {
      restaurent_id: restaurantid,
      qr_code_id: qrcode,
    };

    await syncOrderPayemnt(req);

    updateOrderDetails(qrcode);
    console.log("order payemnt sync");
  };

  const syncposAndsendValuestoDB = async (qrcode, restaurantid) => {
    setLoading(true);
    let req = {
      restaurent_id: restaurantid,
      qr_code_id: qrcode,
      type: 1,
    };
    let res = await syncPOS(req);
    if (res.status === 200) {
      syncOrderPaymentinDB(qrcode, restaurantid);
      setIsPOSEnable(true);
    } else {
      setIsPOSEnable(false);
      setLoading(false);
      console.log("pos sync error");
    }
  };

  const updatePosSyncStatus = async () => {
    let res = await PosSyncStatus();
    if (res.status === 200) {
      console.log("PosSyncStatus");
    } else {
      console.log("PosSyncStatus error");
    }
  };

  const firstsyncposAndsendValuestoDB = async (qrcode, restaurantid) => {
    setLoading(true);
    let req = {
      restaurent_id: restaurantid,
      qr_code_id: qrcode,
    };
    let res = await syncPOS(req);
    if (res.status === 200) {
      updateOrderDetails(qrcode);
      setIsPOSEnable(true);
    } else {
      if (res?.response?.data?.status === 400) {
        setIsPOSEnable(false);
        updateOrderDetails(qrcode);
      }
      if (res?.response?.data?.status === 500) {
        setIsPOSEnable(false);
        dispatch(addOrderDetails(null));
      }
      setLoading(false);
      console.log("pos sync error");
    }
  };

  const firstsyncOrderPaymentinDB = async (qrcode, restaurantid) => {
    let req = {
      restaurent_id: restaurantid,
      qr_code_id: qrcode,
    };
    await syncOrderPayemnt(req);
    firstsyncposAndsendValuestoDB(qrcode, restaurantid);

    console.log("order payemnt sync");
  };

  const chekcingOrderDetails = async (restaurantid, qrcode) => {
    setLoading(true);
    let req = {
      restaurent_id: restaurantid,
      qr_code_id: qrcode,
    };
    let res = await getCheckDetails(req);
    if (res.status === 200) {
      if (res?.message === "Order not found") {
        syncposAndsendValuestoDB(qrcode, restaurantid);
        return;
      } else {
        if (res?.data?.DUE_AMOUNT == 0) {
          firstsyncOrderPaymentinDB(qrcode, restaurantid);
          return;
        }
        if (res?.data?.TranferCheck > 0) {
          syncposAndsendValuestoDB(qrcode, restaurantid);
          return;
        }
        if (res?.data?.DUE_AMOUNT != OrderDetails?.total) {
          syncposAndsendValuestoDB(qrcode, restaurantid);
          return;
        }
      }
      setLoading(false);
      setIsPOSEnable(true);
    } else {
      if (res?.response?.data?.status === 400) {
        setIsPOSEnable(false);
        updateOrderDetails(qrcode);
      }
      if (res?.response?.data?.status === 500) {
        setIsPOSEnable(false);
        dispatch(addOrderDetails(null));
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (restaurantDetails?.tip_selector && !restaurantDetails?.enable_default_tip) {
      dispatch(updateTip(null));
    } else {
      dispatch(
        updateTip({ type: 1, percentage: 0, tip: 0, isCustomOpen: false })
      );
    }

    if (name !== "qrcodeid") {
      let msg = "Please scan Qr CODE again";
      // setLoading(false);
      setMessage(msg);
    } else {
      let timer = setTimeout(() => {
        if (
          restaurantDetails &&
          currentActive?.[0]?.qrcodeid &&
          currentActive?.[0]?.restaurentid
        ) {
          chekcingOrderDetails(
            currentActive?.[0]?.restaurentid,
            currentActive?.[0]?.qrcodeid
          );
          // syncposAndsendValuestoDB(
          //   currentActive?.[0]?.qrcodeid,
          //   currentActive?.[0]?.restaurentid
          // );
          updatePosSyncStatus();
        }
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [restaurantDetails, currentActive?.[0]?.qrcodeid, refresh]);

  return (
    <LaunchWrapper background={SnapayLaunchBackground} orderText={OrderDetails}>
      {openSidenav && (
        <Sidenav
          open={openSidenav}
          onClose={() => setOpenSidenav(false)}
          applogo={logo_restaurent}
        />
      )}
      {restaurantDetails?.show_hamburger_menu && (
        <HumburgerWrapper>
          <span onClick={() => setOpenSidenav(!openSidenav)}>
            <Humburger color={"#000"} />
          </span>
        </HumburgerWrapper>
      )}

      <BlackLayer />

      <section className="upper-section">
        <img src={logo_restaurent} alt="snapay" loading="lazy" />
      </section>
      {message ? (
        <AlertInfo>
          <h1 style={{ textAlign: "center" }}>{message}</h1>
        </AlertInfo>
      ) : (
        // <>
        //   {Restaurentinfo?.Menus?.[0]?.status || OrderDetails ? (
        <>
          <section className="lower-section" dir={langDir?.direction}>
            <>
              {isPOSEnable && (
                // OrderDetails &&
                // Number(OrderDetails?.total) > 0 &&
                <div
                  onClick={() => {
                    !loading &&
                      Number(OrderDetails?.total) > 0 &&
                      handleMenuButton("paybill");
                  }}
                  className="menu billmenu"
                  style={{
                    color: "rgb(103, 67, 63)",
                    background: "#fff",
                    padding: `${!OrderDetails ? "10px 20px" : "12px 20px"}`,
                  }}
                >
                  <div className="billwrap">
                    <div className="paybillRefresh">
                      <div>
                        <LaunchPayBillIcon size={32} />
                        <span>
                          <IntlMessage id="Snapay.LaunchBtnPayBill" />
                        </span>
                      </div>
                      {!OrderDetails && !loading && (
                        <span className="billText">
                          <IntlMessage id="Snapay.LaunchBillText" />
                        </span>
                      )}
                    </div>
                    <div>
                      {loading ? (
                        <i className="Loading">
                          <PaybillLoader color="rgb(103, 67, 63)" />
                        </i>
                      ) : OrderDetails?.total > 0 ? (
                        <>
                          <span>
                            <span className="currency">
                              <IntlMessage id="Snapay.QAR" />
                            </span>
                            {addCommaToNumbers(
                              OrderDetails?.total,
                              calculateServiceCharge(
                                OrderDetails?.total,
                                restaurantDetails?.our_service_charge
                              )
                            )}
                          </span>
                          <i>
                            <RightArrow color="rgb(103, 67, 63)" />
                          </i>
                        </>
                      ) : (
                        <i
                          className="refresh"
                          onClick={() => {
                            setRefresh(!refresh);
                            setLoading(true);
                          }}
                        >
                          <PaybillRefresh color="rgb(103, 67, 63)" />
                        </i>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>

            {Restaurentinfo?.Menus?.[0]?.status && (
              <>
                {Restaurentinfo?.is_menu_link ? (
                  <a
                    href={Restaurentinfo?.is_menu_link}
                    target="_blank"
                    className="menu"
                    style={{
                      color: "rgb(103, 67, 63)",
                      background: "#fff",
                      padding: `${!OrderDetails ? "17px 20px" : "12px 20px"}`,
                    }}
                  >
                    <div>
                      <LaunchMenuIcon size={32} />
                      <span>
                        <IntlMessage id="Snapay.LaunchBtnViewMenu" />
                      </span>
                    </div>
                    <div>
                      <i>
                        <RightArrow />
                      </i>
                    </div>
                  </a>
                ) : (
                  <div
                    onClick={() => handleMenuButton("menu")}
                    className="menu"
                    style={{
                      color: "rgb(103, 67, 63)",
                      background: "#fff",
                      padding: `${!OrderDetails ? "17px 20px" : "12px 20px"}`,
                    }}
                  >
                    <div>
                      <LaunchMenuIcon size={32} />
                      <span>
                        <IntlMessage id="Snapay.LaunchBtnViewMenu" />
                      </span>
                    </div>
                    <div>
                      <i>
                        <RightArrow />
                      </i>
                    </div>
                  </div>
                )}
              </>
            )}

            <main className="info-section">
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <SelectWrapper>
                  <LaunchGlobe />
                  <select
                    onChange={(e) => handleSelectedLanguage(e.target.value)}
                    value={langDir?.lang}
                    style={{
                      color: "#696969",
                      background: "fff",
                      outline: "none",
                    }}
                  >
                    <option value="en">En</option>
                    <option value="ar">Ar</option>
                  </select>
                </SelectWrapper>

                <img src={SnapayLogo} alt="snapay" className="footerIcon" />
              </div>
              <p
                style={{
                  color: "black",
                }}
              >
                <IntlMessage id="Snapay.LaunchYouAccept" />{" "}
                <span onClick={() => navigate("/termsconditions")}>
                  <IntlMessage id="Snapay.LaunchTermAndCondition" />
                </span>
                <a href="https://snapay.tech/" target="_blank">
                  <b>
                    <IntlMessage id="Snapay.LaunchVisitWebsite" />
                  </b>
                </a>
              </p>
            </main>
          </section>
        </>
        //   )
        //   : (
        //     <AlertInfo>
        //       <h1>No Order Found !</h1>
        //     </AlertInfo>
        //   )}
        // </>
      )}
    </LaunchWrapper>
  );
}

const AlertInfo = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    text-shadow: 4px 2px #66433f;
    color: #fff;
    text-align: right;
    font-family: DM Sans;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
const BlackLayer = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
`;

const LaunchWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100dvh;
  background: ${({ background }) => `url(${background})`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 38px 0px 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;

  .upper-section {
    z-index: 9;
    width: 150px;
    height: 150px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      /* width: 131px; */
    }
  }
  .lower-section {
    z-index: 9;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .menu {
      background: #fff;
      color: #67433f;
      border: 2px solid #67433f;
      box-shadow: 0px 0px 20px #67433f;
      position: relative;
      span {
        color: #67433f;
      }
    }
    .billmenu {
      display: flex;
      flex-direction: column;
    }
    .billText {
      font-size: 10px;
      line-height: 14px;
      font-weight: 500;
    }
    // .paybill {
    //   background: rgb(103 67 63 / 100%);
    //   color: #ffffff;
    //   span {
    //     color: #fff;
    //   }
    // }
    .billwrap {
      display: flex;
      justify-content: space-between;
      align-itens: center;
    }

    > div,
    a {
      text-decoration: none;
      margin: 0 20px;
      width: calc(100% - 40px);
      display: flex;
      justify-content: space-between;
      border-radius: 40px;

      div {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      span {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.25px;
        display: flex;
        align-items: center;
        gap: 5px;
      }
      .currency {
        font-size: 12px;
        font-weight: 400;
      }
    }

    .info-section {
      width: 100%;
      background: rgb(255 255 255 / 60%);
      border-radius: 30px 30px 0 0;
      padding: 24px 30px;
      margin-top: 20px;
      display: flex;
      gap: 16px;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      a {
        display: block;
        color: rgb(102, 67, 63);
        margin: 5px 0;
        cursor: pointer;
        font-size: 13px;
        font-weight: 500 !important;
      }

      img {
        height: 70px;
      }

      p {
        color: black;
        font-family: DM Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: center;

        span {
          color: #66433f;
          font-size: 13px;
          font-weight: 500;
        }
      }

      select {
        background: transparent;
        border: 1px solid #fff;
        border-radius: 8px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: #000;
      }
    }

    .arrow-icon {
      svg path {
        stroke: #67433f;
      }
    }
  }
  .Loading {
    width: 26px;
    height: 26px;
    svg {
      animation: rotate2Sm 1.5s linear infinite;
    }
    @keyframes rotate2Sm {
      to {
        transform: rotate(360deg);
      }
    }
  }
  .refresh {
    cursor: pointer;
    height: 32px;
  }
  .footerIcon {
    height: 24px !important;
  }
  .paybillRefresh {
    flex-direction: column;
    align-items: start !important;
    gap: 0 !important;
  }
`;

const HumburgerWrapper = styled.div`
  position: absolute;
  top: 15px;
  left: 10px;
  z-index: 2;
  svg {
    width: 28px;
    height: 28px;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #696969;
  background-color: #fff;
  padding: 8px 14px;
  border: 1px solid rgb(103, 67, 63);
  border-radius: 100px;
  width: "67px";
  overflow: hidden;
`;
