import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  CreateSplitBillApi,
  GetSplitBillDetailsApi,
  executePayment,
  initiateSession,
  paymentOnSkipcash,
  verifyPosCheckStatus,
} from "../../services/Collection";
import { toast } from "react-toastify";

export default function ApplePay({ setApplePayLoading }) {
  console.log(setApplePayLoading, "setApplePayLoading");
  const [paymentSession, setPaymentSession] = useState(null);
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);
  const orderDeails = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );
  const tipAmount = useSelector((state) => state?.orderInformationSlice?.tip);
  const SplitMode = useSelector(
    (state) => state?.orderInformationSlice?.splitBill
  );
  const paymentMode = useSelector(
    (state) => state?.orderInformationSlice?.payBillType
  );
  const [redirectPaymentGatewayURL, setRedirectPaymentGatewayURL] =
    useState(null);
    const serviceDetails = useSelector(
      (state) => state?.orderInformationSlice?.serviceCharge
    );  

  console.log("redirectPaymentGatewayURL----", redirectPaymentGatewayURL);

  const initFatoorah = () => {
    if (window.myFatoorah) {
      const elmt = document.getElementById("apple-element");
      console.log(elmt, "apple-element");
      let amount =
        paymentMode === "PayFullBill"
          ? findAmount("fullpayment", "executivepayment")
          : SplitMode?.type === "CustomAmount"
          ? findAmount("CustomAmount", "executivepayment")
          : SplitMode?.type === "PayforYourItem"
          ? findAmount("PayforYourItem", "executivepayment")
          : SplitMode?.type === "DivideEqually" &&
            findAmount("DivideEqually", "executivepayment");
      console.log(amount, "initFatoorah amount");
      var config = {
        countryCode: paymentSession?.CountryCode, // Here, add your Country Code you receive from InitiateSession Endpoint.
        sessionId: paymentSession?.SessionId, // Here you add the "SessionId" you receive from InitiateSession Endpoint.
        cardViewId: "apple-element",
        currencyCode: "QAR", // Here, add your Currency Code.
        amount: amount, // Add the invoice amount.
        // sessionStarted: sessionStarted,
        // sessionCanceled: sessionCanceled
        callback: payment,
        style: {
          frameHeight: 52,
          button: {
            height: "52px",
            background: "#fff",
            text: "", // Accepted texts ["", "Buy with", "Pay with", "Check Out with", "Continue with", "Book with", "Donate with", "Subscribe with", "Reload with", "Add Money with", "Top Up with", "Order with", "Rent with", "Support with", "Contribute with", "Tip with", "Set Up"]
            borderRadius: "8px",
          },
          h4: {
            marginLeft: "10px",
          },
        },
      };

      window.myFatoorahAP.init(config);
    }
  };

  const findAmount = (type, mode) => {
    if (type === "fullpayment") {
      let fullpayment =
        mode === "executivepayment"
          ? orderDeails?.total + Number(tipAmount?.tip ?? 0) + serviceDetails?.splitServiceAmount
          : orderDeails?.total;
      return fullpayment;
    } else if (type === "CustomAmount") {
      let CustomAmount =
        mode === "executivepayment"
          ? Number(SplitMode?.total) + Number(tipAmount?.tip ?? 0) + serviceDetails?.splitServiceAmount
          : Number(SplitMode?.total);
      return CustomAmount;
    } else if (type === "PayforYourItem") {
      let PayforYourItem =
        mode === "executivepayment"
          ? Number(SplitMode?.total) + Number(tipAmount?.tip ?? 0) + serviceDetails?.splitServiceAmount
          : Number(SplitMode?.total);
      return PayforYourItem;
    } else if (type === "DivideEqually") {
      let DivideEqually =
        mode === "executivepayment"
          ? Number(SplitMode?.total) + Number(tipAmount?.tip ?? 0) + serviceDetails?.splitServiceAmount
          : Number(SplitMode?.total);
      return DivideEqually;
    }
  };

  const handleCreateSplitBill = async (payload) => {
    let req = {
      order_id: orderDeails ? orderDeails.id : null,
      split_method_customer_count: payload?.split_method_customer_count,
      transaction_id: payload?.transaction_id,
      split_method_customer_paid: payload?.split_method_customer_paid,
      total_amount: orderDeails?.sub_total,
      due_amount: payload?.due_amount,
      paid_amount: payload?.amount,
      split_method_type: payload?.type,
    };

    let res = await CreateSplitBillApi(req);

    if (res.status === 200) {
      setRedirectPaymentGatewayURL(payload?.PaymentURL);
      setApplePayLoading(false);
    } else {
      setApplePayLoading(false);
      toast.error(
        res.error || res.response.data.message || "Please try again",
        { theme: "colored" }
      );
    }
  };

  const handleGetSplitBillDetails = async (payload) => {
    let res = await GetSplitBillDetailsApi(payload.orderId);

    if (res.status === 200) {
      if (res?.data?.transaction_id === null) {
        // split - custom amount
        if (payload?.type === "2") {
          let due_amount = orderDeails?.sub_total - payload?.amount;
          let tempObj = {
            ...payload,
            split_method_customer_paid: 1,
            due_amount: due_amount,
            split_method_customer_count: 1,
          };
          handleCreateSplitBill(tempObj);
        }

        // split - pay for you items
        if (payload?.type === "3") {
          let due_amount = orderDeails?.sub_total - payload?.amount;
          let tempObj = {
            ...payload,
            split_method_customer_paid: 1,
            due_amount: due_amount,
            split_method_customer_count: 1,
          };
          handleCreateSplitBill(tempObj);
        }

        //split - divide equally
        if (payload?.type === "4") {
          let due_amount = orderDeails?.total - payload?.amount;
          let tempObj = {
            ...payload,
            split_method_customer_paid: SplitMode?.noPeopleYouPayFor,
            due_amount: due_amount,
            split_method_customer_count: SplitMode?.noTotalPeople,
          };
          handleCreateSplitBill(tempObj);
        }
      } else {
        // split - custom amount
        if (payload?.type === "2") {
          if (res?.data?.type === "2") {
            let due_amount = res?.data?.due_amount - payload?.amount;
            let tempObj = {
              ...payload,
              split_method_customer_paid: 1,
              due_amount: due_amount,
              split_method_customer_count: 1,
            };
            handleCreateSplitBill(tempObj);
          } else {
            let due_amount = res?.data?.due_amount - payload?.amount;
            let tempObj = {
              ...payload,
              split_method_customer_paid:
                Number(res?.data?.split_method_customer_paid) + 1,
              due_amount: due_amount,
              split_method_customer_count:
                res?.data?.split_method_customer_count,
            };
            handleCreateSplitBill(tempObj);
          }
        }

        // split -  pay for your items
        if (payload?.type === "3") {
          let due_amount = res?.data?.due_amount - payload?.amount;
          let tempObj = {
            ...payload,
            split_method_customer_paid: 1,
            due_amount: due_amount,
            split_method_customer_count: 1,
          };
          handleCreateSplitBill(tempObj);
        }

        //split - divide equally
        if (payload?.type === "4") {
          let due_amount = res?.data?.due_amount - payload?.amount;
          let tempObj = {
            ...payload,
            split_method_customer_paid:
              Number(res?.data?.split_method_customer_paid) +
              SplitMode?.noPeopleYouPayFor,
            due_amount: due_amount,
            split_method_customer_count: res?.data?.split_method_customer_count,
          };
          handleCreateSplitBill(tempObj);
        }
      }
    } else if (res?.response?.data?.status === 400) {
      // split - custom amount
      if (payload?.type === "2") {
        let due_amount = orderDeails?.sub_total - payload?.amount;
        let tempObj = {
          ...payload,
          split_method_customer_paid: 1,
          due_amount: due_amount,
          split_method_customer_count: 1,
        };
        handleCreateSplitBill(tempObj);
      }

      // split - pay for you items
      if (payload?.type === "3") {
        let due_amount = orderDeails?.sub_total - payload?.amount;
        let tempObj = {
          ...payload,
          split_method_customer_paid: 1,
          due_amount: due_amount,
          split_method_customer_count: 1,
        };
        handleCreateSplitBill(tempObj);
      }

      //split - divide equally
      if (payload?.type === "4") {
        let due_amount = orderDeails?.sub_total - payload?.amount;
        let tempObj = {
          ...payload,
          split_method_customer_paid: SplitMode?.noPeopleYouPayFor,
          due_amount: due_amount,
          split_method_customer_count: SplitMode?.noTotalPeople,
        };
        handleCreateSplitBill(tempObj);
      }
    } else {
      toast.error(
        res.error ||
          res.response.data.message ||
          "Please try again in sometime",
        { theme: "colored" }
      );
      setApplePayLoading(false);
    }
  };

  const myFatoorahPayment = async (payload) => {
    let req = {
      order_id: orderDeails ? orderDeails.id : null,
      restaurent_id: orderDeails?.restaurent_id,
      amount:
        paymentMode === "PayFullBill"
          ? findAmount("fullpayment")
          : SplitMode?.type === "CustomAmount"
          ? findAmount("CustomAmount")
          : SplitMode?.type === "PayforYourItem"
          ? findAmount("PayforYourItem")
          : SplitMode?.type === "DivideEqually" && findAmount("DivideEqually"),

      tip: tipAmount?.tip ?? 0,
      //full- 1 , partia; - 2 , spec - 3
      type:
        paymentMode === "PayFullBill"
          ? "1"
          : SplitMode?.type === "CustomAmount"
          ? "2"
          : SplitMode?.type === "PayforYourItem"
          ? "3"
          : SplitMode?.type === "DivideEqually" && "4",
      InvoiceId: payload?.InvoiceId,
      service_amount: serviceDetails?.splitServiceAmount
    };

    if (SplitMode?.type === "PayforYourItem") {
      let tempArray = [];
      for (let i = 0; i < SplitMode?.selectedItems?.length; i++) {
        let tempObj = {
          order_item_id: SplitMode?.selectedItems?.[i]?.id,
          paid_amount:
            (SplitMode?.selectedItems?.[i]?.amount /
              SplitMode?.selectedItems?.[i]?.quantity) *
            SplitMode?.selectedItems?.[i]?.customQuantity,
          paid_quantity: SplitMode?.selectedItems?.[i]?.customQuantity,
        };
        tempArray.push(tempObj);
      }
      req.payment_items_details = tempArray;
    }

    let res = await paymentOnSkipcash(req);
    if (res.status === 200) {
      let obj = {
        orderId: orderDeails?.id,
        transaction_id: res?.data?.id,
        type: req?.type,
        amount: req?.amount,
        PaymentURL: payload?.PaymentURL,
      };
      if (req?.type === "1") {
        setRedirectPaymentGatewayURL(payload?.PaymentURL);
        setApplePayLoading(false);
      } else {
        handleGetSplitBillDetails(obj);
      }
    } else {
      setApplePayLoading(false);
      toast.error(res.error || res.response.data.message, { theme: "colored" });
    }
  };

  const getExecutePayment = async (response) => {
    console.log("getExecutePayment-response", response);
    setApplePayLoading(true);
    let req = {
      SessionId: response.sessionId,
      InvoiceValue:
        paymentMode === "PayFullBill"
          ? findAmount("fullpayment", "executivepayment")
          : SplitMode?.type === "CustomAmount"
          ? findAmount("CustomAmount", "executivepayment")
          : SplitMode?.type === "PayforYourItem"
          ? findAmount("PayforYourItem", "executivepayment")
          : SplitMode?.type === "DivideEqually" &&
            findAmount("DivideEqually", "executivepayment"),
      restaurent_id: Number(currentActive?.[0]?.restaurentid),
      qrcodeid: Number(currentActive?.[0]?.qrcodeid),
      UserDefinedField :`Snapay-${orderDeails?.id}`
    };
    console.log("getExecutePayment-req", req);

    let res = await executePayment(req);
    if (res.status === 200) {
      myFatoorahPayment(res?.data?.Data);
    } else {
      setApplePayLoading(false);
      toast.error("Please try again in sometime", { theme: "colored" });
    }
  };

  const posCheckStatus = async () => {
    let params = new URLSearchParams();
    params.append("order_id", orderDeails ? orderDeails.id : null);
    let res = await verifyPosCheckStatus(params.toString());
    if (res.status === 200) {
      return true;
    } else {
      toast.error(res?.response?.data?.message || res?.message || res?.error, {
        theme: "colored",
      });
      return false;
    }
  };

  async function payment(response) {
    const isPosCheckedLock = await posCheckStatus();
    isPosCheckedLock && getExecutePayment(response);
  }

  const getSession = async () => {
    let req = {
      CustomerIdentifier: "123",
      restaurent_id: Number(currentActive?.[0]?.restaurentid),
    };
    let res = await initiateSession(req);
    if (res.status === 200) {
      setPaymentSession(res?.data?.Data);
    } else {
      console.log("pos sync error");
    }
  };

  const openSkipCashPaymentGateway = () => {
    console.log("redirectPaymentGatewayURL", redirectPaymentGatewayURL);
    if (redirectPaymentGatewayURL) {
      window.open(redirectPaymentGatewayURL, "_self");
    }
  };
  useEffect(() => {
    openSkipCashPaymentGateway();
  }, [redirectPaymentGatewayURL]);

  useEffect(() => {
    if (paymentSession) {
      initFatoorah();
    }
  }, [paymentSession]);

  useEffect(() => {
    getSession();
  }, []);

  return <div id="apple-element"></div>;
}
