import { Flex, Rate } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { SmartFeedbackApi } from "../../services/Collection";
import { useNavigate } from "react-router-dom";
import { BackIcon } from "../../Utils/Icons";
import { toast } from "react-toastify";
import IntlMessage from "../../Utils/IntlMessage";

const validationArr = [
  "is_first_visit",
  "gratification_rating",
  "sanitation_rating",
  "taste_rating",
  "staff_feedback",
  "return_intent",
  "additional_comments",
  "name",
  "email",
  "type",
];

const SmartFeedback = () => {
  const theme = useSelector((state) => state?.theme?.branding);
  const restaurant = useSelector(
    (state) => state?.restaurentSlice?.restuarentProfileInfo
  );
  const qrCodeID = useSelector(
    (state) => state?.restaurentSlice?.restaurentData
  );
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    is_first_visit: null,
    gratification_rating: 0,
    sanitation_rating: 0,
    taste_rating: 0,
    staff_feedback: 0,
    return_intent: null,
    additional_comments: "",
    name: "",
    email: "",
    type: 1,
    qr_code_id: qrCodeID?.id,
  });
  const [loading, setLoading] = useState(false);

  const handleValues = (value, string) => {
    setFormData((preData) => ({ ...preData, [string]: value }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const validation = validationArr.every(
      (ele) =>
        formData?.[ele] !== "" &&
        formData?.[ele] !== 0 &&
        formData?.[ele] !== null
    );

    if (!validation) {
      toast.error("Please fill required field");
      setLoading(false);
      return;
    }
    const req = { ...formData, restaurent_id: restaurant?.id };
    const res = await SmartFeedbackApi(req);
    if (res?.status == 200) {
      setLoading(false);
      toast.success(res?.message || "Feedback has been added successfully");
      navigate(-1);
    } else {
      toast.error(res?.message || "Something went wrong");
      setLoading(false);
    }
  };

  return (
    <SmartFeedbackStyle themeColor={theme?.theme_color}>
      <div className="smart-feedback-header">
        <i onClick={() => navigate(-1)}>
          <BackIcon color={theme?.theme_color} />
        </i>
      </div>

      <div>
        <p>
          <IntlMessage id="smartFeedback.FirstTime" /> <span>*</span>
        </p>
        <div>
          <div
            className={formData?.is_first_visit ? "btn active" : "btn"}
            onClick={(e) => handleValues(true, "is_first_visit")}
          >
            <IntlMessage id="smartFeedback.Yes" />
          </div>
          <div
            className={
              formData?.is_first_visit !== null && !formData?.is_first_visit
                ? "btn active"
                : "btn"
            }
            onClick={(e) => handleValues(false, "is_first_visit")}
          >
            <IntlMessage id="smartFeedback.NO" />
          </div>
        </div>
      </div>
      <div>
        <p>
          <IntlMessage id="smartFeedback.OverallSatisfaction" /> <span>*</span>
        </p>
        <Flex gap="middle" vertical>
          <Rate
            style={{ color: theme?.theme_color }}
            onChange={(e) => handleValues(e, "gratification_rating")}
            value={formData?.gratification_rating}
          />
        </Flex>
      </div>
      <div>
        <p>
          <IntlMessage id="smartFeedback.RateTheHygiene" /> <span>*</span>
        </p>
        <Flex gap="middle" vertical>
          <Rate
            style={{ color: theme?.theme_color }}
            onChange={(e) => handleValues(e, "sanitation_rating")}
            value={formData?.sanitation_rating}
          />
        </Flex>
      </div>
      <div>
        <p>
          <IntlMessage id="smartFeedback.TasteOfFood" /> <span>*</span>
        </p>
        <Flex gap="middle" vertical>
          <Rate
            style={{ color: theme?.theme_color }}
            onChange={(e) => handleValues(e, "taste_rating")}
            value={formData?.taste_rating}
          />
        </Flex>
      </div>
      <div style={{ width: "90%" }}>
        <p>
          <IntlMessage id="smartFeedback.OurStaff" /> <span>*</span>
        </p>
        <Rate
          style={{ color: theme?.theme_color }}
          onChange={(e) => handleValues(e, "staff_feedback")}
          value={formData?.staff_feedback}
        />
      </div>
      <div>
        <p>
          <IntlMessage id="smartFeedback.BackToEat" /> <span>*</span>
        </p>
        <div>
          <div
            className={formData?.return_intent ? "btn active" : "btn"}
            onClick={(e) => handleValues(true, "return_intent")}
          >
            <IntlMessage id="smartFeedback.Yes" />
          </div>
          <div
            className={
              formData?.return_intent !== null && !formData?.return_intent
                ? "btn active"
                : "btn"
            }
            onClick={(e) => handleValues(false, "return_intent")}
          >
            <IntlMessage id="smartFeedback.NO" />
          </div>
        </div>
      </div>
      <div style={{ width: "90%" }}>
        <p>
          <IntlMessage id="smartFeedback.WantToTellUs" /> <span>*</span>
        </p>
        <input
          type="text"
          name="additional_comments"
          onChange={(e) =>
            handleValues(e?.target?.value, "additional_comments")
          }
        />
      </div>
      <div style={{ width: "90%" }}>
        <p>
          <IntlMessage id="smartFeedback.Name" /> <span>*</span>
        </p>
        <input
          type="text"
          name="name"
          onChange={(e) => handleValues(e?.target?.value, "name")}
        />
      </div>
      <div style={{ width: "90%" }}>
        <p>
          <IntlMessage id="smartFeedback.EmailAddress" /> <span>*</span>
        </p>
        <input
          type="email"
          name="email"
          onChange={(e) => handleValues(e?.target?.value, "email")}
        />
      </div>
      <button className="submit" onClick={handleSubmit}>
        {loading ? (
          <IntlMessage id="Snapay.Loading" />
        ) : (
          <IntlMessage id="verifyotp.button.submit" />
        )}
      </button>
    </SmartFeedbackStyle>
  );
};

export default SmartFeedback;

const SmartFeedbackStyle = styled.div`
  margin-top: 20px;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: rgb(87, 89, 98);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  height: calc(100dvh - 20px);
  overflow: scroll;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 600;

    p {
      text-align: center;
    }
  }

  .smart-feedback-header {
    display: flex;
    flex-direction: row;
    i {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 10px;
    }
  }
  .back-icon {
    display: flex;
    width: 100%;
    padding: 0 10px;
    display: flex;
    // align-items: start;
  }
  .btn {
    width: 90px;
    margin-right: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    outline: none;
    user-select: none;
    cursor: pointer;
    border: ${({ themeColor }) => `1px solid ${themeColor}`};
    color: ${({ themeColor }) => themeColor};
    background-color: transparent;
    border-radius: 100px;
    padding: 6px 16px;
    :hover {
      background-color: ${({ themeColor }) => `${themeColor}50`};
    }
  }

  .submit {
    background-color: ${({ themeColor }) => themeColor};
    width: 90%;
    color: rgb(255, 255, 255);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    outline: none;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 12px;
    margin: 0 0 20px 0;
    border-radius: 8px;
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 2px;
  }

  input {
    width: 100%;
    color: rgb(87, 89, 98);
    background-color: transparent;
    border: 1px solid rgba(87, 89, 98, 0.2);
    outline: none;
    padding: 0 20px;
    height: 45px;
    border-radius: 50px;
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
  }

  .active {
    background-color: ${({ themeColor }) => `${themeColor}50`};
  }

  p > span {
    color: red;
  }
`;
