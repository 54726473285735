import { createSlice } from "@reduxjs/toolkit";

export const themeSlice = createSlice({
  name: "theme",
  initialState: {
    branding: {
      id: 1,
      restaurentId: 14,
      display_image: null,
      display_image_for_web_background: true,
      ar_welcome_text: "مرحبا بكم في مطعمنا",
      button_primary_color: "#66433F",
      button_secondary_color: "#001817",
      button_text_color: "#ffffff",
      theme_color: "#66433F",
      background_color: "#ffffff",
      header_color: "#b1905a",
      text_color: "#171717",
      createdAt: "2022-09-02T10:56:53.000Z",
      updatedAt: "2023-12-08T11:45:48.000Z",
      RestaurentId: 14,
    },
  },

  reducers: {
    customTheme: (state, action) => {
      // if (action.payload) {
      //   state.branding = action.payload;
      // }
    },
  },
});

export const { customTheme } = themeSlice.actions;
export default themeSlice.reducer;
