import { createSlice } from "@reduxjs/toolkit";

export const chatHistorySlice = createSlice({
  name: "chatHistory",
  initialState: {
    chat: [],
    showChatLinks: false
  },

  reducers: {
    updateChat: (state, action) => {
      if (action.payload) {
        // state.chat=[]
        state.chat = [...state.chat, ...action.payload];

      }
    },
    restartChat: (state, action) => {
      state.chat = []
    },
    showLink: (state, action) => {
      state.showChatLinks = action.payload
    }
  },
});

export const { updateChat, restartChat, showLink } = chatHistorySlice.actions;
export default chatHistorySlice.reducer;