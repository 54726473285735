import { createSlice } from "@reduxjs/toolkit";
  
  export const menuSlice = createSlice({
    name: "menu",
    initialState: {
      // QrIdList=[

      // ],

        menuID:null,
        qrCodeID:null
    },
    
    reducers: {
      showMenuByID: (state, action) => {
        if (action.payload) {
          state.menuID = action.payload;
        } 
      },
      showMenuByqrID:(state,action) => {
        if(action.payload) {
          state.qrCodeID = action.payload
        }
      }
    },
  });
  
  export const { showMenuByID,showMenuByqrID } = menuSlice.actions;
  export default menuSlice.reducer;