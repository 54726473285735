import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  authSucess,
  checkQrcodeIsMaster,
  loginWithMasterQrCode,
} from "../store/userLoginReducer";
import { toast } from "react-toastify";
import PaymentSuccess from "../pages/Payment/PaymentSuccess";
import { checkAuraCustomerExists } from "../services/Collection";
import GuestMenuListing from "../pages/guest/GuestMenuListing";
import Launch from "../module/LaunchScreen/Launch";
import PayBill from "../module/BillSummary/PayBill";
import PaymentSuccessful from "../app/components/PaymentSuccessful";
import TermAndCondition from "../app/components/TermAndCondition";
import PaymentFailed from "../app/components/PaymentFailed";
import PublicLayout from "../layout/PublicLayout";
import SmartFeedback from "../components/FeedBackBillDetail/SmartFeedback";
import MasterQrCode from "../pages/MasterQrCode/MasterQrCode";
import NotFound from "../pages/NotFound";
// import SelectLanguage from "../pages/SelectLanguage";
// import OrderVeri from "../pages/OrderVerification/OrderVeri";
// import VerifyOtp from "../pages/VerifyOtp/VerifyOtp";
// import AddFoodList from "../pages/AddFoodList/AddFoodList";
// import CartWithDiscount from "../pages/CartWithDiscount/CartWithDiscount";
// import CartWithOutDiscount from "../pages/CartWithOutDiscount/CartwithOutDiscount";
// import DescriptionWithFreeItem from "../pages/DescriptionWithFreeItem/DescriptionWithFreeItem";
// import PaymentOne from "../pages/Payment/PaymentOne";
// import CardInfo from "../pages/Payment/CardInfo";
// import CardFrontSideScanner from "../pages/Payment/CardFrontSideScanner";
// import Payment from "../pages/Payment/Payment";
// import ChatOption from "../pages/ChatOption/ChatOption";
// import ThankYou from "../pages/ThankYou/ThankYou";
// import Feedback from "../pages/Feedback/Feedback";
// import UserFeedback from "../pages/UserFeedback/UserFeedback";
// import AlertScreen from "../pages/AlertScreen/AlertScreen";
// import Cart from "../pages/Cart";
// import TestCallbackURL from "../Test/TestCallbackURL";
// import { CartWithAdress } from "../pages/CartWithAddress";
// import { SelectDeliveryLocation } from "../pages/SelectDeliveryLocation";
// import { AddDeliveryAddress } from "../pages/AddDeliveryAddress";
// import { SelectMapLocation } from "../pages/SelectLocationFromMap";
// import UnifiedPayment from "../pages/Payment/UnifiedPayment";
// import { UnifiedOrdersDetails } from "../components/UnifiedOrderDetails/UnifiedOrdersDetails";
// import GoogleMapDistance from "../components/GoogleMap/GoogleMapDistance";
// import PrePayment from "../pages/PrePayment/PrePayment";
// import LoginOptions from "../pages/guest/LoginOptions";
// import GetDirection from "../components/GoogleMap/GetDirection";
// import SelectQRType from "../pages/DeliveryModule/SelectQRType";
// import TestPayment from "../Test/TestPayment";

function PublicRoute({ isAuthenticated, isRestaurentRecivingOrder, QrCodeID }) {
  if (isAuthenticated) {
    // if (isRestaurentRecivingOrder) {
    //   return <Navigate to="/retry" replace />;
    // }
    return <Navigate to={`/${QrCodeID}/addfoodlist`} replace />;
  }
  return <PublicLayout />;
}

// This function is used for Authentication when user's credentials will be invalid.
function PrivateRoute({ isAuthenticated }) {
  if (isAuthenticated === null) return <Navigate to={`/`} />;
  return <PublicLayout />;
}

export default function AglutRoutes({ qrcodeType }) {
  const isRestaurentRecivingOrder = useSelector(
    (state) =>
      state.restaurentSlice.restaurentData?.Menu?.Restaurent?.disable_order
  );
  // const QrCodeID = useSelector(
  //   (state) => state?.StoreForMultiUsers?.workStation?.slice(-1)?.[0]?.id
  // );
  // const isMaster = useSelector(
  //   (state) => state?.restaurentSlice?.restaurentData?.is_master
  // );
  // const currentActiveID = getAlreadyScanedID?.workStation?.slice(-1)?.[0]?.id;

  const isMaster = useSelector((state) => state?.userAuth?.IsMaster);
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);
  const getMultiUserData = useSelector(
    (state) => state?.userAuth?.IsMasterAuth
  );
  const getAllParams = window?.location?.search
    ?.substring(1)
    ?.split("=")
    ?.toString()
    ?.split("&");

  const dispatch = useDispatch();

  const [AuthenticationToken, setAuthenticationToken] = useState("");

  let getToken = () => {
    if (getMultiUserData?.length > 0) {
      for (let i = 0; i < getMultiUserData.length + 1; i++) {
        if (!isMaster) {
          if (
            Number(getMultiUserData[i]?.restaurent_id) ===
            Number(currentActive?.[0]?.restaurentid)
          ) {
            setAuthenticationToken(getMultiUserData[i]?.token);
            dispatch(authSucess(getMultiUserData[i]));
            return;
          } else {
            setAuthenticationToken(null);
          }
        } else {
          setAuthenticationToken(null);
        }
      }
    }
  };

  const thisUserIsFromAura = async (payload) => {
    let Obj = {};
    payload.map((el, i) => {
      let a = el.split(",");
      Obj[a[0]] = a[1];
    });

    let NewObject = {
      ...Obj,
      countrycode: Obj.countrycode.replace("%20", ""),
      firstname: Obj.firstname.replace("%20", ""),
      lastname: Obj.lastname.replaceAll("%20", " "),
    };

    if (NewObject) {
      let req = {
        name: NewObject.firstname + NewObject.lastname,
        country_code: "+" + NewObject.countrycode,
        mobile_no: NewObject.userphone,
        // auth_key: NewObject.token,
        restaurent_id: NewObject.restaurantid,
        aqlut_restaurent_id: NewObject.Aqlut_rest_ID,
      };

      let res = await checkAuraCustomerExists(req);
      if (res.status === 200) {
        storeTokenForMaster(res.data);
      } else {
        toast.error(res.message, { theme: "colored" });
      }
    }
  };

  const storeTokenForMaster = (payload) => {
    let userDetails = { currentID: currentActive?.[0]?.qrcodeid, ...payload };

    if (getMultiUserData.length > 0) {
      const updatedArray = getMultiUserData?.filter(
        (item) =>
          item.id !== userDetails.id &&
          item.restaurent_id !== userDetails.restaurent_id
      );
      updatedArray.push(userDetails);
      if (
        Number(updatedArray[updatedArray.length - 1]?.restaurent_id) ===
        Number(userDetails?.restaurent_id)
      ) {
        dispatch(checkQrcodeIsMaster(true));
        dispatch(loginWithMasterQrCode(updatedArray));
      }
      getToken();
    } else {
      let arr = [];
      arr.push(userDetails);
      if (arr && arr.length > 0) {
        dispatch(loginWithMasterQrCode(arr));
        getToken();
      }
    }
  };

  useEffect(() => {
    if (getAllParams?.length >= 7) {
      thisUserIsFromAura(getAllParams);
    } else {
      getToken();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMultiUserData, currentActive, isMaster]);

  useEffect(() => {}, [currentActive?.[0]?.qrcodeid]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <PublicRoute
              isAuthenticated={AuthenticationToken}
              isRestaurentRecivingOrder={isRestaurentRecivingOrder}
              QrCodeID={currentActive?.[0]?.qrcodeid}
            />
          }
        >
          {isMaster ? (
            <Route path={`/`} element={<MasterQrCode />} />
          ) : (
            <>
              {qrcodeType === "Dine In" && (
                <Route path={`/`} element={<Launch />} />
              )}
              {/* <Route path={`/testingpayment`} element={<TestPayment />} /> */}
              <Route
                path={`/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/paybill`}
                element={<PayBill />}
              />
              <Route
                path={
                  qrcodeType === "Dine In"
                    ? `/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/menu`
                    : "/"
                }
                element={<GuestMenuListing />}
              />
              <Route
                path={`${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/snapaymentsuccessful`}
                element={<PaymentSuccessful />}
              />
              <Route
                path={`/snapaymentsuccessful`}
                element={<PaymentSuccessful />}
              />
              <Route
                path={`${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/snapaymentfailed`}
                element={<PaymentFailed />}
              />
              <Route path={`/termsconditions`} element={<TermAndCondition />} />

              {/* <Route
                path={`/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/login-options`}
                element={<LoginOptions />}
              /> */}

              {/* <Route
                path={`/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/select-qrtype`}
                element={<SelectQRType />}
              /> */}

              <Route
                path={`/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/guest-menu`}
                element={<GuestMenuListing />}
              />

              {/* <Route
                path={`/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/orderveri`}
                element={<OrderVeri />}
              />
              <Route
                path={`/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/verifyotp`}
                element={<VerifyOtp />}
              />
              <Route path={`/testingMaps`} element={<GoogleMapDistance />} /> */}
            </>
          )}
        </Route>

        <Route element={<PrivateRoute isAuthenticated={AuthenticationToken} />}>
          {/* <Route path={`/alertscreen`} element={<AlertScreen />} /> */}
          {/* <Route path={`/${currentActive?.[0]?.qrcodeid}/master`} element={<MasterQrCode />} /> */}
          {/* <Route path="/retry" element={<AlertScreen />} /> */}
          {/* <Route path="/closed" element={<AlertScreen />} /> */}
          {/* <Route path="/cartwithdiscount" element={<CartWithDiscount />} /> */}
          {/* <Route
            path={`/${currentActive?.[0]?.qrcodeid}/addfoodlist`}
            element={<AddFoodList />}
          /> */}

          {/* <Route
            path={`/${currentActive?.[0]?.qrcodeid}/get-direction`}
            element={<GetDirection />}
          /> */}

          {/* <Route
            path={`/${currentActive?.[0]?.qrcodeid}/cart`}
            element={<Cart />}
          /> */}

          {/* <Route
            path={`/${currentActive?.[0]?.qrcodeid}/cart-address`}
            element={<CartWithAdress />}
          /> */}

          {/* <Route
            path={`/${currentActive?.[0]?.qrcodeid}/pre-payment`}
            element={<PrePayment />}
          /> */}

          {/* <Route
            path={`/${currentActive?.[0]?.qrcodeid}/add-location`}
            element={<AddDeliveryAddress />}
          /> */}

          {/* <Route
            path={`/${currentActive?.[0]?.qrcodeid}/select-location`}
            element={<SelectDeliveryLocation />}
          /> */}

          {/* <Route
            path={`/${currentActive?.[0]?.qrcodeid}/map-location`}
            element={<SelectMapLocation />}
          /> */}

          {/* <Route
            path={`/${currentActive?.[0]?.qrcodeid}/unified-payment`}
            element={<UnifiedPayment />}
          /> */}

          {/* <Route
            path="/CartWithOutDiscount"
            element={<CartWithOutDiscount />}
          /> */}
          {/* <Route
            path="/DescriptionWithFreeItem"
            element={<DescriptionWithFreeItem />}
          /> */}
          {/* <Route path="/freeFoodItem" element={<AddFoodList />} /> */}
          {/* <Route path="/chatoption" element={<ChatOption />} /> */}
          {/* <Route
            path={`/${currentActive?.[0]?.qrcodeid}/payment`}
            element={<Payment />}
          /> */}
          {/* <Route path="/thanks" element={<ThankYou />} /> */}
          {/* <Route
            path={`/${currentActive?.[0]?.qrcodeid}/user-feedback`}
            element={<UserFeedback />}
          /> */}
          {/* <Route path="/feedback" element={<Feedback />} /> */}

          {/* <Route
            path={`/${currentActive?.[0]?.qrcodeid}/pre-payment-order-details`}
            element={<UnifiedOrdersDetails />}
          /> */}

          {/* <Route path="/select-payment-method" element={<PaymentOne />} /> */}
          {/* <Route path="/payment-card-info" element={<CardInfo />} /> */}
          {/* <Route
            path="/payment-card-scan-front"
            element={<CardFrontSideScanner />}
          /> */}
          <Route
            path={`${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/payment-success`}
            element={<PaymentSuccess />}
          />
          <Route path={`/SmartFeedback`} element={<SmartFeedback />} />
          {/* <Route path="/testing" element={<TestCallbackURL />} /> */}
        </Route>
        <Route path="/notfound" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
