import React from "react";

function FreeItem({ direction, colorStyle, freeItems }) {

  return (
    <>
      {freeItems?.length &&
        freeItems.map((udx) => (
          <div className="PriceSectionBox" dir={direction}>
            <div className="QuantitiyWrapper">
              <div className="QunatityContainer">
                <img src={require("../../assets/Discount.png")} alt="free" />
                <div className="DiscoundContainer">
                  <p style={{ color: colorStyle }}>Free Item</p>
                  <h5>
                    {direction === "ltr" ? udx?.Item?.name : udx?.Item?.ar_name}
                  </h5>
                  {/* <small>QTY : 1</small> */}
                </div>
                {/* <div className="Innermenu">
            <small>Free (45 kcal)</small>
            <h6>QAR 125</h6>
          </div> */}
              </div>
            </div>
          </div>
        ))}
    </>
  );
}

export default FreeItem;
