import { Drawer } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import {
  FacebookIcon,
  InstagramIcon,
  SidenavCross,
  SnapchatIcon,
  TickTokIcon,
  WebsiteIcon,
} from "../../Utils/Icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import IntlMessage from "../../Utils/IntlMessage";

export default function Sidenav({ open, onClose, applogo }) {
  const selectedLang = useSelector((state) => state?.selectLang);
  const direction = useSelector((state) => state?.selectLang?.direction);
  const navigate = useNavigate();
  const restaurentInfo = useSelector(
    (state) => state?.restaurentSlice?.restuarentProfileInfo
  );
  let logo_restaurent = applogo;

  return (
    <div>
      <Drawer
        placement={selectedLang?.lang === "en" ? "left" : "right"}
        closable={false}
        onClose={onClose}
        open={open}
        key={"left"}
        width={300}
        style={{ padding: 0 }}
      >
        <SidenavWrapper themeColor={"rgb(103, 67, 63)"} dir={direction}>
          <div className="sidenav-top-icons">
            <i onClick={onClose}>
              <SidenavCross color={"rgb(103, 67, 63)"} />
            </i>
          </div>

          <div className="app-logo-wrapper">
            <img src={logo_restaurent} alt="Logo" />
          </div>

          <div className="sidenav-option-wrapper">
            <p onClick={() => navigate(`/SmartFeedback`)}>
              <IntlMessage id="sidenav.GiveFeedback" />
            </p>
          </div>
        </SidenavWrapper>
        <SocialSidenavWrapper themeColor={"rgb(103, 67, 63)"}>
          {restaurentInfo?.facebook_link && (
            <i>
              <a
                href={restaurentInfo?.facebook_link}
                target={"_blank"}
                style={{ display: "flex" }}
              >
                <FacebookIcon color={"rgb(103, 67, 63)"} />
              </a>
            </i>
          )}

          {restaurentInfo?.instagram_link && (
            <i>
              <a
                href={restaurentInfo?.instagram_link}
                target={"_blank"}
                style={{ display: "flex" }}
              >
                <InstagramIcon color={"rgb(103, 67, 63)"} />
              </a>
            </i>
          )}

          {restaurentInfo?.website_link && (
            <i>
              <a
                href={restaurentInfo?.website_link}
                target={"_blank"}
                style={{ display: "flex" }}
              >
                <WebsiteIcon color={"rgb(103, 67, 63)"} />
              </a>
            </i>
          )}
          {restaurentInfo?.snapchat_link && (
            <i>
              <a
                href={restaurentInfo?.snapchat_link}
                target={"_blank"}
                style={{ display: "flex" }}
              >
                <SnapchatIcon color={"rgb(103, 67, 63)"} />
              </a>
            </i>
          )}

          {restaurentInfo?.tiktok_link && (
            <i>
              <a
                href={restaurentInfo?.tiktok_link}
                target={"_blank"}
                style={{ display: "flex" }}
              >
                <TickTokIcon color={"rgb(103, 67, 63)"} />
              </a>
            </i>
          )}
        </SocialSidenavWrapper>
      </Drawer>
    </div>
  );
}

const SocialSidenavWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 300px;

  i {
    border: ${({ themeColor }) => `1px solid ${themeColor}`};
    padding: 6px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-item: center;
    cursor: pointer;
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;
const SidenavWrapper = styled.div`
  width: 300px;
  /* width: 100%; */
  height: calc(100dvh - 48px);
  //   background: #a7a5a5ee;
  background: white;

  display: flex;
  flex-direction: column;

  .sidenav-top-icons {
    padding: 14px 16px;
    > i {
      width: fit-content;
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }

  .app-logo-wrapper {
    width: 100%;
    min-height: 118px;
    max-height: 100%;
    margin: 10px 0 20px 0;
    // margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 133px;
      height: 133px;
      object-fit: contain;
    }
  }

  .sidenav-option-wrapper {
    width: 100%;
    height: 400px;
    background: white;
    padding: 20px;

    color: #575962;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    gap: 24px 0;
  }

  .active {
    color: ${({ themeColor }) => themeColor};
  }
`;
