import React from 'react'
import { CardInfo } from './CardInfo'
import { PaymentListing } from './PaymentListing'
import { PaymentOne } from './PaymentOne'

function GlobalStyle() {
  return (
   <>
    <CardInfo />
    <PaymentOne />
    <PaymentListing />
   </>
  )
}

export default GlobalStyle