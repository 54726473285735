import React, { useEffect, useState } from "react";

import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { addOrderDetails } from "../../store/orderPayment";
import { useNavigate } from "react-router-dom";

function MultipleOrderModal({ open, handleClose }) {
  const multipleOrders = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Orders
  );

  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);

  const CurrentOrderDetails = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const updateOrderDetailInStore = (payload) => {
    dispatch(addOrderDetails(payload));
    navigate(`/${currentActive?.[0]?.qrcodeid}/pre-payment`, {
      state: payload,
    });
  };

  const unifiedNotification = useSelector(
    (state) => state?.isUnifiedSlice?.unifiedNotification
  );

  return (
    <div>
  

      <SwipeableDrawer
        anchor={"bottom"}
        // id={id}
        open={open}
        // anchorEl={anchorEl}
        onClose={handleClose}
        // dir={direction}
        style={{ borderRadius: "20px" }}
      >
        <MenuBoxWrapper style={styleMenuBoxForEnglish}>
          <div className="Popup-title">
            <h3>Your Orders</h3>
          </div>
          <div className="multipleOrderBox">
            {multipleOrders?.map((el, index) => {
              return (
                <div
                  className="ordersDetails"
                  onClick={() => updateOrderDetailInStore(el)}
                >
                  <div className="priceListing">
                    <label>{index + 1}.</label>
                    <label>QAR: {el?.total}</label>
                  </div>
                  <div>
                    <label className="orderStatus">
                      <b>{el?.order_type}</b>
                    </label>
                  </div>

                  {unifiedNotification.map(
                    (order) =>
                      order?.id == el?.id && (
                        <span className="markasRead"></span>
                      )
                  )}
                </div>
              );
            })}
          </div>
        </MenuBoxWrapper>
      </SwipeableDrawer>
    </div>
  );
}

export default MultipleOrderModal;

const Pricedetails = styled.div`
  margin-left: ${({ dir }) => dir === "ltr" && "20px"};
  margin-right: ${({ dir }) => dir === "rtl" && "20px"};

  p {
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.02em;

    color: #ffffff;
  }

  span {
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.02em;

    color: rgba(255, 255, 255, 0.6);
  }
`;

const AddItemDrawerParent = styled.div`
  .PriceHeader {
    display: flex;
    margin-top: 15px;
  }

  .PriceHeading {
    font-family: "Jost";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #f55a2c;
  }
`;

const PricesWrapper = styled.div`
  margin-left: 20px;

  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    margin: 0;
    color: #000;
  }

  span {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.02em;

    color: #000;
  }
`;

const PriceSection = styled.div`
  background-color: white;
  padding: 16px 0px;

  .Innersection {
    width: 95%;
    margin: 0 auto;
  }

  .PriceHeader {
    display: flex;
  }

  .PriceHeading {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    ${"" /* margin-left: 11px; */}
    color: #fc2b6e;
  }
  .Innermenu {
    padding: 6px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .InnerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const PriceNumberContainer = styled.div`
  display: inline-grid;
  span {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height */
    letter-spacing: 0.02em;
    color: #202020;
  }
`;

const ChekboxContainer = styled.div`
  display: flex;
  position: relative;

  .PriceListCheckbox {
    margin-bottom: 17px;
    accent-color: #fc2b6e;
  }
`;

const FoodModalBtnsWrapper = styled.div`
  height: 100%;
  margin-bottom: 90px;
`;

const styleMenuBoxForEnglish = {
  width: "100%",
  height: "380px",
  padding: "10px 0",
  background: "#ffff",
  color: "#000",
  borderRadius: "20px",

  "&::-webkit-scrollbar": {
    width: "0.4em",
  },
};

const MenuBoxWrapper = styled.div`
  width: 100%;
  overflow-y: scroll;

  .ordersDetails {
    position: relative;
    border-bottom: 1px solid #979797;
    padding: 20px 10px;
    margin: 0px 10px;
    display: flex;
    justify-content: space-between;

    .priceListing {
      display: flex;
      gap: 20px;
    }
    .orderStatus {
    }
  }

  .Popup-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 6px;
    img {
      margin-right: 20px;
      margin-top: 5px;
      margin-bottom: 3px;
      height: 13px;
    }
    h3 {
      padding-left: 15px;
    }
  }

  .markasRead {
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 30px;
    background: red;
    top: 25px;
    right: 0px;
  }
`;
