import { useSelector } from "react-redux";
import AglutRoutes from "./routes/AglutRoutes";
import GlobalStyle from "./style/index";
import "./style/Global.css";
import { ThemeProvider } from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import Pusher from "pusher-js";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { addNotifications } from "./store/Notifications";
import NotificationSound from "../src/assets/sound/notificationSound.mp3";
import { updateChat } from "./store/ChatHistory";
import {
  addOrderDetails,
  updateServiceCharge,
  updateTip,
} from "./store/orderPayment";
import { posStatus } from "./store/posStatus";
import { addItem } from "./store/selectItem";
import {
  checkposPayment,
  getMenuBrandingByRestaurent,
  getQrCodeDetails,
  getrestaurentIdByQrcode,
  restaurentQrcode,
} from "./services/Collection";
import {
  LoaderForRestuarentID,
  setQrCodeID,
  setQrCodeIDAndRestuarentID,
} from "./store/MultiUserStore";
import { IntlProvider } from "react-intl";
import AppLocale from "./langugae/languageindex";
import {
  checkAndUpdateUser,
  unifiedNotificationPersist,
} from "./store/UnifiedUser/UnifiedUser";
import { Backdrop, CircularProgress } from "@mui/material";
import { DeviceUUID } from "device-uuid";
import { addRestaurentData, Restaurentinfo } from "./store/restaurenDetail";
import AlertModalForCompleteTakeaway from "./components/Modal/AlertModalForCompleteTakeaway";
import { AqlutGif } from "./Utils/Images";
import { LoaderWithAnimation } from "./style/GobalStyle";
import { customTheme } from "./store/AppTheme";
import CryptoJS from "crypto-js";
import { checkQrcodeIsMaster, supertoken } from "./store/userLoginReducer";
import { calculateServiceCharge } from "./Utils/CommanFunctions";
import Scanner from "./components/Scan";
// var AES = require("crypto-js/aes");

let REACT_CLUSTER = "ap2";
let REACT_CHATBOT_CHANNEL = "All-ChatBoat-messages";
let REACT_EVENT_CALL_WAITER = "Event-All-ChatBoat-Messages";
let REACT_PAYMENT_CHANNEL = "Order-Item-Paid";
let REACT_PAYMENT_EVENT = "Event-Order-Item-Paid";

//Channel name
let REACT_ACCEPT_CHANNEL = "Accept-Order";
let CHANNEL_UNDER_PREPARATION_ORDER = "Under-Preparation";
let REACT_CHANNEL_MARK_READY = "Mark-Ready";
let REACT_CHANNEL_CANCEL_ORDER = "Cancel-Order";
let REACT_CHANNEL_COMPLETE_ORDER = "Complete-Order";
let REACT_CHANNEL_PLACE_ORDER = "Order-Placed-Customer";
let REACT_CHANNEL_POS_ENABLE = "Pos-Status";
let REACT_CHANNEL_TAKEAWAY_COMPLETE_ORDER = "Takeaway-Complete-Order";
//Event name________________
let REACT_EVENT_PLACE_ORDER = "Event-Order-Placed-Customer";
let REACT_EVENT_ACCEPT_ORDER = "Accept-Order";
let REACT_EVENT_UNDER_PREPRATION = "Under-Prepration";
let REACT_EVENT_MARK_READY = "Mark-Ready";
let REACT_EVENT_CANCEL_ORDER = "Cancel-Order";
let REACT_EVENT_COMPLETE_ORDER = "Complete-Order";
let REACT_EVENT_POS_ENABLE = "Event-Pos-Status";
let REACT_EVENT_TAKEAWAY_COMPLETE_ORDER = "Event-Takeaway-Complete-Order";

const pusher = new Pusher("b3f9147b93b8a156b8c0", {
  cluster: REACT_CLUSTER,
});

function App() {
  const { orderDeails, data } = useSelector(
    ({ orderInformationSlice, userAuth }) => ({
      ...orderInformationSlice,
      ...userAuth,
    })
  );

  const RestuarantID = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.restaurent_id
  );

  const dispatch = useDispatch();
 

  // const audioPlayer = useRef(null);

  // for welcome screen splash
  const [welcomeSplash, setWelcomeSplash] = useState(false);
  let WelcomeScreenIDs = sessionStorage.getItem("loader");

  const [channel, setChannel] = useState(null);
  const [paymentChannel, setPaymentChannel] = useState(null);
  const [isWaiterCalled, setWaiterCalled] = useState(false);
  const [message, setMessage] = useState("");
  const language = useSelector((state) => state?.selectLang?.lang);
  const currentAppLocale = AppLocale[language];
  // const QrCodeID =
  //   window?.location?.search?.substring(1)?.split("=")?.[1] ||
  //   window.location.pathname?.split("/")?.[1];

  const multipleOrders = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Orders
  );
  const FetchAllMessage = useSelector(
    (state) => state?.isUnifiedSlice?.unifiedNotification
  );
  const customerDetails = useSelector((state) => state?.userAuth?.data);

  // const IsUnifiedUser = useSelector((state) => state?.userAuth?.data);
  const IsUnifiedUser = useSelector((state) => state?.isUnifiedSlice);

  const orderDetails = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );

  // console.log(orderDetails, "orderDetailsorderDetails");

  // const getPaidNotification = useCallback((data) => {
  //   if (data) {
  //     var today = new Date();
  //     var time = today.getHours() + ":" + today.getMinutes();
  //     const obj1 = {
  //       type: "boatMessage",
  //       message: data?.message,
  //       time: time,
  //     };
  //     dispatch(updateChat([obj1]));
  //   }
  //   if (audioPlayer) {
  //     audioPlayer?.current.play();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const getCallWaiterMessage = useCallback(
  //   (data) => {
  //     if (data) {
  //       let message = data;
  //       var today = new Date();
  //       var time = today.getHours() + ":" + today.getMinutes();
  //       if (message && !isWaiterCalled) {
  //         const obj1 = {
  //           type: "waiterMessage",
  //           message: message,
  //           time: time,
  //         };
  //         dispatch(updateChat([obj1]));
  //         audioPlayer.current.play();
  //         setWaiterCalled(true);
  //       }
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   []
  // );
  // useEffect(() => {
  //   if (channel && orderDeails?.id) {
  //     channel.bind(
  //       REACT_EVENT_CALL_WAITER + orderDeails?.id,
  //       getCallWaiterMessage
  //     );
  //   }

  //   return () => {
  //     if (channel && orderDeails?.id) {
  //       channel.unbind(REACT_EVENT_CALL_WAITER + orderDeails?.id);
  //       console.log(channel, "channelchannel");
  //     }
  //   };

  //   // return () => {
  //   //   if (channel && orderDeails?.id) {
  //   //     pusher.unsubscribe(REACT_EVENT_CALL_WAITER + orderDeails?.id);
  //   //     channel.unbind(
  //   //       REACT_EVENT_CALL_WAITER + orderDeails?.id,
  //   //       function (data) {
  //   //         console.log(data, "notiffyyyyyyyyyyyyyyy");
  //   //       }
  //   //     );
  //   //   }
  //   // };

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [channel, orderDeails?.id]);
  //PAYMENT CHANNEL

  // useMemo(() => {
  //   if (paymentChannel && orderDeails?.id) {
  //     paymentChannel.bind(
  //       REACT_PAYMENT_EVENT + orderDeails?.id,
  //       getPaidNotification
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [paymentChannel, orderDeails?.id]);

  // useEffect(
  //   () => {
  //     if (orderDeails?.id) {
  //       setChannel(pusher.subscribe(REACT_CHATBOT_CHANNEL + orderDeails?.id));
  //       setPaymentChannel(
  //         pusher.subscribe(REACT_PAYMENT_CHANNEL + orderDeails?.id)
  //       );

  //       const id = orderDeails?.id;
  //       //Place order channel
  //       const placeOrdeChannel = pusher.subscribe(
  //         REACT_CHANNEL_PLACE_ORDER + data.id
  //       );
  //       placeOrdeChannel.bind(
  //         REACT_EVENT_PLACE_ORDER + data.id,
  //         function (data) {
  //           if (data) {
  //             let message = data?.message;
  //             audioPlayer.current.play();

  //             dispatch(addNotifications(message));
  //           }
  //         }
  //       );
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [orderDeails?.id]
  // );

  // const AcceptOrderNotification = (id) => {
  //   const orderAcceptchannel = pusher.subscribe(REACT_ACCEPT_CHANNEL + id);
  //   orderAcceptchannel.bind(REACT_EVENT_ACCEPT_ORDER + id, function (data) {
  //     if (data) {
  //       let obj = {
  //         id: id,
  //         eventid: 2,
  //         message: data?.message,
  //       };
  //       let message = data?.message;
  //       if (arguments) {
  //         audioPlayer.current.play();

  //         if (IsUnifiedUser?.is_aura_user) {
  //           RemoveDuplicateMessage(obj);
  //           // dispatch(unifiedNotificationPersist(obj));
  //         } else {
  //           dispatch(addNotifications(message));
  //         }
  //       }
  //     }
  //   });
  //   return () => {
  //     if (orderAcceptchannel) {
  //       pusher.unsubscribe(REACT_ACCEPT_CHANNEL + id);
  //       orderAcceptchannel.unbind(
  //         REACT_EVENT_ACCEPT_ORDER + id,
  //         function (data) {
  //           console.log(data, "notiffyyyyyyyyyyyyyyy");
  //         }
  //       );
  //     }
  //   };
  // };

  // const OrderPrepartionNotification = (id) => {
  //   const orderPrepartionChannel = pusher.subscribe(
  //     CHANNEL_UNDER_PREPARATION_ORDER + id
  //   );
  //   //ordwe prepartion event
  //   orderPrepartionChannel.bind(
  //     REACT_EVENT_UNDER_PREPRATION + id,
  //     function (data) {
  //       if (data) {
  //         let obj = {
  //           id: id,
  //           eventid: 3,
  //           message: data?.message,
  //         };
  //         let message = data?.message;
  //         audioPlayer.current.play();

  //         if (IsUnifiedUser?.is_aura_user) {
  //           RemoveDuplicateMessage(obj);
  //           // dispatch(unifiedNotificationPersist(obj));
  //         } else {
  //           dispatch(addNotifications(message));
  //         }
  //       }
  //     }
  //   );

  //   return () => {
  //     if (orderPrepartionChannel) {
  //       pusher.unsubscribe(CHANNEL_UNDER_PREPARATION_ORDER + id);
  //       orderPrepartionChannel.unbind(
  //         REACT_EVENT_UNDER_PREPRATION + id,
  //         function (data) {
  //           console.log(data, "notiffyyyyyyyyyyyyyyy");
  //         }
  //       );
  //     }
  //   };
  // };

  // const MarkAsReadyNotification = (id) => {
  //   const orderMarkReadyChannel = pusher.subscribe(
  //     REACT_CHANNEL_MARK_READY + id
  //   );
  //   orderMarkReadyChannel.bind(REACT_EVENT_MARK_READY + id, function (data) {
  //     if (data) {
  //       let obj = {
  //         id: id,
  //         eventid: 4,
  //         message: data?.message,
  //       };
  //       let message = data?.message;
  //       audioPlayer.current.play();

  //       if (IsUnifiedUser?.is_aura_user) {
  //         RemoveDuplicateMessage(obj);
  //         // dispatch(unifiedNotificationPersist(obj));
  //       } else {
  //         dispatch(addNotifications(message));
  //       }
  //     }
  //   });

  //   return () => {
  //     if (orderMarkReadyChannel) {
  //       pusher.unsubscribe(REACT_CHANNEL_MARK_READY + id);
  //       orderMarkReadyChannel.unbind(
  //         REACT_EVENT_MARK_READY + id,
  //         function (data) {
  //           console.log(data, "notiffyyyyyyyyyyyyyyy");
  //         }
  //       );
  //     }
  //   };
  // };

  // const OrderCancelNotification = (id) => {
  //   const orderCancelChannel = pusher.subscribe(
  //     REACT_CHANNEL_CANCEL_ORDER + id
  //   );

  //   orderCancelChannel.bind(REACT_EVENT_CANCEL_ORDER + id, function (data) {
  //     if (data) {
  //       let obj = {
  //         id: id,
  //         eventid: 5,
  //         message: data?.message,
  //       };
  //       let message = data?.message;
  //       audioPlayer.current.play();
  //       if (IsUnifiedUser?.is_aura_user) {
  //         RemoveDuplicateMessage(obj);
  //         // dispatch(unifiedNotificationPersist(obj));
  //       } else {
  //         dispatch(addNotifications(message));
  //       }
  //       dispatch(addOrderDetails(null));
  //     }
  //   });

  //   return () => {
  //     if (orderCancelChannel) {
  //       pusher.unsubscribe(REACT_CHANNEL_CANCEL_ORDER + id);
  //       orderCancelChannel.unbind(
  //         REACT_EVENT_CANCEL_ORDER + id,
  //         function (data) {
  //           console.log(data, "notiffyyyyyyyyyyyyyyy");
  //         }
  //       );
  //     }
  //   };
  // };

  // const CompleteOrderStatusNotification = (id) => {
  //   const orderCompleteChannel = pusher.subscribe(
  //     REACT_CHANNEL_COMPLETE_ORDER + id
  //   );

  //   orderCompleteChannel.bind(REACT_EVENT_COMPLETE_ORDER + id, function (data) {
  //     if (data) {
  //       let obj = {
  //         id: id,
  //         eventid: 6,
  //         message: data?.message,
  //       };
  //       console.log(obj, "notiffyyyyyyyyyyyyyyy");
  //       let message = data?.message;
  //       audioPlayer.current.play();

  //       if (IsUnifiedUser?.is_aura_user) {
  //         RemoveDuplicateMessage(obj);
  //         // dispatch(unifiedNotificationPersist(obj));
  //       } else {
  //         dispatch(addNotifications(message));
  //       }
  //     }
  //   });

  //   return () => {
  //     if (orderCompleteChannel) {
  //       pusher.unsubscribe(REACT_CHANNEL_COMPLETE_ORDER + id);
  //       orderCompleteChannel.unbind(
  //         REACT_EVENT_COMPLETE_ORDER + id,
  //         function (data) {
  //           console.log(data, "notiffyyyyyyyyyyyyyyy");
  //         }
  //       );
  //     }
  //   };
  // };

  const RemoveDuplicateMessage = (newNotification) => {
    if (FetchAllMessage?.length > 0) {
      let array = [];
      let flag = 0;
      for (let i = 0; i < FetchAllMessage?.length; i++) {
        if (FetchAllMessage[i].id === newNotification.id) {
          flag++;
          if (FetchAllMessage[i].eventid !== newNotification.eventid) {
            array.push(newNotification);
          } else {
            array.push(FetchAllMessage[i]);
          }
        } else {
          array.push(FetchAllMessage[i]);
        }
      }
      if (flag === 0) {
        array.push(newNotification);
      }
      dispatch(unifiedNotificationPersist(array));
    } else {
      let arr = [];
      arr.push(newNotification);
      dispatch(unifiedNotificationPersist(arr));
    }
  };

  // useEffect(() => {
  //   if (IsUnifiedUser?.is_aura_user) {
  //     multipleOrders?.map((order) => {
  //       AcceptOrderNotification(order?.id);
  //       OrderPrepartionNotification(order?.id);
  //       MarkAsReadyNotification(order?.id);
  //       OrderCancelNotification(order?.id);
  //       CompleteOrderStatusNotification(order?.id);
  //     });
  //   }
  // }, [multipleOrders]);

  // useEffect(() => {
  //   if (!IsUnifiedUser?.is_aura_user) {
  //     AcceptOrderNotification(orderDeails?.id);
  //     OrderPrepartionNotification(orderDeails?.id);
  //     MarkAsReadyNotification(orderDeails?.id);
  //     OrderCancelNotification(orderDeails?.id);
  //     CompleteOrderStatusNotification(orderDeails?.id);
  //   }
  // }, [orderDeails?.id]);

  // const [alertForCompleteOrderModal, setAlertForCompleteOrderModal] =
  //   useState(null);

  // const CompletedOrderNotificationInTakeaway = (id) => {
  //   const completedOrderchannel = pusher.subscribe(
  //     REACT_CHANNEL_TAKEAWAY_COMPLETE_ORDER + id
  //   );
  //   completedOrderchannel.bind(
  //     REACT_EVENT_TAKEAWAY_COMPLETE_ORDER + id,
  //     function (data) {
  //       if (data) {
  //         console.log(data, "datdatdatdatda---------------");
  //         if (data?.message) {
  //           setAlertForCompleteOrderModal(data?.message);
  //         }
  //       }
  //     }
  //   );
  //   return () => {
  //     if (completedOrderchannel) {
  //       pusher.unsubscribe(REACT_CHANNEL_TAKEAWAY_COMPLETE_ORDER + id);
  //       completedOrderchannel.unbind(
  //         REACT_EVENT_TAKEAWAY_COMPLETE_ORDER + id,
  //         function (data) {
  //           console.log(data, "notiffyyyyyyyyyyyyyyy");
  //         }
  //       );
  //     }
  //   };
  // };

  // useEffect(() => {
  //   CompletedOrderNotificationInTakeaway(customerDetails?.id);
  // }, [customerDetails]);

  useEffect(() => {
    const PosStatus = pusher.subscribe(REACT_CHANNEL_POS_ENABLE + RestuarantID);
    PosStatus.bind(REACT_EVENT_POS_ENABLE + RestuarantID, function (data) {
      if (data) {
        dispatch(posStatus(data));
        localStorage.setItem("isPOSEnable", data?.pos_integration_status);
        dispatch(addItem({ pos: data }));
      }
    });
  }, [RestuarantID]);

  const [posPayment, setPosPayment] = useState(null);

  const posPaymentRealtime = useCallback(async (data) => {
    if (data) {
      let req = {
        order_id: data.order_id,
        type: data.type,
        amount: data.amount,
      };
      let res = await checkposPayment(req);
      if (res.status === 200) {
      }
    }

    return () => {
      if (posPayment) {
        pusher.unsubscribe(`Pos-Payment${RestuarantID}`);
        posPayment.unbind(`Event-Pos-Payment${RestuarantID}`, function () {});
        posPayment.unbind(`Event-Pos-Payment${RestuarantID}`, function () {});
      }
    };

    // setLoading(false);
  }, []);

  useMemo(() => {
    if (posPayment)
      posPayment.bind(`Event-Pos-Payment${RestuarantID}`, posPaymentRealtime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posPayment]);

  useEffect(() => {
    if (RestuarantID) {
      setPosPayment(pusher.subscribe(`Pos-Payment${RestuarantID}`));
    }
  }, [RestuarantID]);

  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);

  const setQrAndResValuetoStore = (payload) => {
    if (getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.length > 0) {
      const updatedArray =
        getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.filter(
          (item) => Number(item.restaurentid) !== Number(payload.restaurentid)
        );
      updatedArray.push(payload);
      if (updatedArray) dispatch(setQrCodeIDAndRestuarentID(updatedArray));
    } else {
      if (payload) dispatch(setQrCodeIDAndRestuarentID([payload]));
    }
  };

  const getAllParams = window?.location?.search
    ?.substring(1)
    ?.split("=")
    ?.toString()
    ?.split("&");

  const getRestuarantQrCodeid = async (id) => {
    let res = await restaurentQrcode(id);
    if (res.status === 200) {
      let parameters = {
        qrcodeid: res.data.id,
        restaurentid: res.data.restaurent_id,
      };
      setQrAndResValuetoStore(parameters);

      // setQrValuetoStore(res.data.id);
    } else {
      toast.error(res?.message, { theme: "colored" });
    }
  };

  const loader = useSelector(
    (state) => state?.StoreForMultiUsers?.waitingForResturantId
  );
  const getRestuarantid = async (id) => {
    dispatch(LoaderForRestuarentID(false));
    let res = await getrestaurentIdByQrcode(id);
    if (res?.status === 200) {
      let parameters = {
        qrcodeid: res?.data?.id,
        restaurentid: res?.data?.restaurent_id,
      };
      dispatch(Restaurentinfo(res?.data?.Restaurent));
      setQrAndResValuetoStore(parameters);
      dispatch(LoaderForRestuarentID(true));
    } else {
      setMessage("Please Scan Another Barcode...");
      toast.error(res?.error || res?.response?.data?.message, {
        theme: "colored",
      });
    }
  };

  // const currentActive =
  //   getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);

  // const SplashAnimationFunc = (id) => {
  //   if (WelcomeScreenIDs) {
  //     let data = JSON.parse(WelcomeScreenIDs);
  //     let updatedArray = data?.some((el) => el === id);
  //     console.log(updatedArray, "updatedArray");
  //     if (!updatedArray) {
  //       data?.push(id);
  //       sessionStorage.setItem("loader", JSON.stringify(data));
  //     }
  //   } else {
  //     sessionStorage.setItem("loader", JSON.stringify([id]));
  //   }

  //   if (!WelcomeScreenIDs?.includes(id)) {
  //     // console.log("welcome screen");
  //     setWelcomeSplash(true);

  //     setTimeout(() => {
  //       setWelcomeSplash(false);
  //     }, 7000);
  //   }
  // };

  const getResturantTheme = async (qrcodeid) => {
    let res = await getMenuBrandingByRestaurent(qrcodeid);
    if (res?.status === 200) {
      delete res?.data?.Restaurent;
      dispatch(customTheme(res?.data));
      // document.body.setAttribute("style", `background: ${res?.data?.background_color}`);
      document.body.setAttribute("style", `background: ${"ffffff"}`);
    }
  };

  // useEffect(() => {
  //   let id = getAllParams?.[0].split(",")?.[1];
  //   let name  = getAllParams?.[0].split(",")?.[0];
  //   if (id) {
  //     // SplashAnimationFunc(id);
  //     getResturantTheme(id);
  //   } else {
  //     // SplashAnimationFunc(currentActive?.[0]?.qrcodeid);
  //     getResturantTheme(currentActive?.[0]?.qrcodeid);
  //   }
  // }, []);

  const [qrcodeType, setQrcodeType] = useState(null);
  const MenuListing = async (id) => {
    let params = new URLSearchParams();
    params.append("qr_code_id", id);

    let response = await getQrCodeDetails(params.toString());
    if (response?.status === 200) {
      setQrcodeType(response?.data?.QrCodeGroup?.group_type);
      dispatch(addRestaurentData(response?.data));

      let restaurentDetailsForServiceCharge = response?.data?.Menu?.Restaurent;

      if (restaurentDetailsForServiceCharge?.our_service_charge >= 0) {
        const amount = calculateServiceCharge(
          orderDetails?.total,
          restaurentDetailsForServiceCharge?.our_service_charge
        );
        let obj = {
          totalServiceAmount: amount,
          serviceAmount: amount,
          splitServiceAmount: amount,
        };
        dispatch(updateServiceCharge(obj));
      }
      if (!response?.data?.Menu?.Restaurent?.tip_selector) {
        dispatch(
          updateTip({ type: 1, percentage: 0, tip: 0, isCustomOpen: false })
        );
      }
      let data = {
        pos_integration_status:
          response?.data?.QrCodeGroup?.Restaurent?.pos_integration_status,
      };
      dispatch(posStatus(data));
      dispatch(checkQrcodeIsMaster(response?.data?.is_master));
      return true;
    }
  };

  useEffect(() => {
    if (getAllParams?.length > 0) {
      let name = getAllParams?.[0].split(",")?.[0];
      let id = getAllParams?.[0].split(",")?.[1];
      console.log("name", name);
      if (name === "qrcodeid" && id) {
        getResturantTheme(id);
        let response = MenuListing(id);
        response && getRestuarantid(id);
      } else {
        getResturantTheme(currentActive?.[0]?.qrcodeid);
        let response = MenuListing(currentActive?.[0]?.qrcodeid);
        response && getRestuarantid(currentActive?.[0]?.qrcodeid);
      }
      dispatch(checkAndUpdateUser(false));
    }
    // else if (getAllParams?.length >= 7) {
    //   getAllParams.map((el) => {
    //     if (el.includes("Aqlut_rest_ID")) {
    //       let id = el.split(",")?.[1];
    //       getRestuarantQrCodeid(id);
    //     }
    //   });
    //   dispatch(checkAndUpdateUser(true));
    // }
  }, []);

  const callBackCryptojs = () => {
    const sid = process.env.REACT_APP_SID;
    const authToken = process.env.REACT_APP_AUTH;

    var encrypted = CryptoJS.AES.encrypt(
      `${sid}:${authToken}`,
      "SnapaySecretKey"
    )?.toString();

    if (encrypted) {
      dispatch(supertoken(encrypted));
    }
  };

  useEffect(() => {
    callBackCryptojs();
  }, []);

  if (welcomeSplash) {
    return (
      <LoaderWithAnimation>
        <img src={AqlutGif} alt="loadinggif" className="aminationLogo" />;
      </LoaderWithAnimation>
    );
  }

  if (message) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          background: customTheme?.background_color,
        }}
      >
        {/* <p style={{ color: "black", marginTop: "5px" }}>{message}</p> */}
        <Scanner message={message}/>
      </div>
    );
  }

  if (!loader) {
    return (
      <Backdrop
        sx={{
          color: "#FC2B6E",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loader}
      >
        <div
          style={{
            display: "block",
            textAlign: "center",
            background: customTheme?.background_color,
          }}
        >
          <CircularProgress color="inherit" />
          <p style={{ color: "black", marginTop: "5px" }}>Processing...</p>
        </div>
      </Backdrop>
    );
  }

  const theme = {
    primaryColor: "red",
    paymentButtonBg: "linear-gradient(91.33deg, #FC2B6E 0%, #BC00A9 100%)",
    paymentButtonColor: "#FFFFFF",
    checkoutPriceColor: "#F55A2C",
    paymentSuccessColor: "#00AE3B",
    UserChatBg: "#F8F8FB",
    AglutChatBg: "#FFF7F4",
    AglutSelectedMessageBg: "#FFBBA2",
  };

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider
        locale={currentAppLocale}
        defaultLocale="en"
        messages={currentAppLocale?.messages}
      >
        <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <GlobalStyle />

        {/* <audio ref={audioPlayer} src={NotificationSound} /> */}

        {loader && qrcodeType && <AglutRoutes qrcodeType={qrcodeType} />}
      </IntlProvider>
    </ThemeProvider>
  );
}

export default App;
