import axios from "axios";
import { store } from "../store/store";

const EndPoint =
  process.env.REACT_APP_BASEURL + process.env.REACT_APP_API_VERSION;

const Api = axios.create({
  timeout: 1000000,
  baseURL: EndPoint,
});

Api.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
Api.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

Api.interceptors.request.use(
  (config) => {
    const getAllParams = window?.location?.search
      ?.substring(1)
      ?.split("=")
      ?.toString()
      ?.split("&");
    let id = getAllParams?.[0].split(",")?.[1];
    const lang = store.getState().selectLang.lang;
    const token = store.getState().userAuth?.token;

    config.headers = {
      Authorization: token,
      QrCodeid: store.getState()?.restaurentSlice?.restaurentData?.id || id,
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImlkIjo2MjcsInJlc3RhdXJlbnRfaWQiOjE0LCJuYW1lIjoiYWRzY2FzZHNhIiwiY291bnRyeV9jb2RlIjoiKzk3NCIsIm1vYmlsZV9ubyI6IjEyMzEyMzEyMyIsIm90cCI6MTIzNDU2LCJ2ZXJpZnlfb3RwX3N0YXR1cyI6ZmFsc2UsInN0YXR1cyI6ZmFsc2UsImNyZWF0ZWRBdCI6IjIwMjItMTItMzFUMDk6MTg6MTIuMDAwWiIsInVwZGF0ZWRBdCI6IjIwMjItMTItMzFUMDk6MTg6MTIuMDAwWiJ9LCJpYXQiOjE2NzI0ODIyOTB9.2YgEwH7DhGvgWD2jqr4Q9FL7_g9O4xMgQ0u2qoRvBQc`,
      "Accept-Language": lang,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default Api;
