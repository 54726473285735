import { CircularProgress } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  getOrderDetails,
  getPaymentStatus,
  getTransactionDetails,
  getUpdateOrderDetails,
  internalWebHook,
  sendEmailReceipts,
  syncOrderPayemnt,
  syncPOS,
  feedbackSendByCustomer as SmartFeedbackApi,
} from "../../services/Collection";
import {
  addOrderDetails,
  splitBill,
  updatedOrderFromPos,
  wayToPayBillType,
} from "../../store/orderPayment";
import { LoaderWapper, ThemeButton } from "../../style/SnapayGobalStyle";
import {
  ArrowLeftIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  GivefeedbackSvg,
  GoogleIcon,
} from "../../Utils/Icons";
import { SnapayGrayBackground } from "../../Utils/Images";
import { aqlutstorage, containerProfile } from "../../Utils/ImageStorage";
import IntlMessage from "../../Utils/IntlMessage";
import OrderedItemsBill from "./OrderedItemsBill";
import loadingLock from "../../lotties/loadingLock.json";
import { Flex, Rate } from "antd";

const PaymentSuccessful = () => {
  const customTheme = useSelector((state) => state?.theme?.branding);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderDeails = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );
  const order = useSelector(
    (state) => state?.orderInformationSlice?.PosSyncedOrder
  );
  const Restaurentinfo = useSelector(
    (state) => state?.restaurentSlice?.restuarentProfileInfo
  );
  let SnapayLaunchBackground =
    aqlutstorage + containerProfile + Restaurentinfo?.background_landing_page;
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);
  // const restaurantDetails = useSelector(
  //   (state) => state?.restaurentSlice?.restaurentData
  // );
  const paymentDetails = useSelector((state) => state?.orderInformationSlice);
  const [loading, setLoading] = useState(true);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [transactionData, setTransactionData] = useState();
  const [showYourShare, setShowYourShare] = useState(false);
  let styleObj = {
    rotate: !showYourShare ? "none" : "180deg",
  };
  const OrderDetails = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );
  const langDir = useSelector((state) => state?.selectLang);
  const [emailID, setEmailID] = useState(null);
  const [reloadNeeded, setReloadNeeded] = useState(false);
  const location = useLocation();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLock,
  };
  const qrCodeID = useSelector(
    (state) => state?.restaurentSlice?.restaurentData
  );
  const [isFeedbackSumbitted, setIsFeedbackSumbitted] = useState(false);

  console.log(transactionData, "transactionData");

  const handleNavigation = () => {
    // if (transactionData?.type === "1") {
    //   navigate(`/?qrcodeid=${currentActive?.[0]?.qrcodeid}`);
    // } else {
    //   navigate(
    //     `/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/paybill`
    //   );
    // }
    navigate(`/?qrcodeid=${currentActive?.[0]?.qrcodeid}`);
  };

  const transactionDetails = async () => {
    let res = await getTransactionDetails(orderDeails?.id);

    if (res?.status === 200) {
      setTransactionData(res?.data);
      setReloadNeeded(false);
    } else {
      setReloadNeeded(true);
    }
  };

  const [orderDetails, setOrderDetails] = useState([]);

  const getOrderDetailsData = async (order) => {
    let res = await getOrderDetails(order.id);
    if (res.status === 200) {
      setOrderDetails(res?.data);
      let updatedArr = [];

      for (let index = 0; index < res?.data?.posOrderItems?.length; index++) {
        updatedArr.push({
          ...res?.data?.posOrderItems[index],
          ischecked: false,
        });
      }
      let response = { ...res?.data, posOrderItems: updatedArr };

      dispatch(updatedOrderFromPos(response));
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const updateOrderDetails = async (qrcode) => {
    let payload = {
      order_id: OrderDetails ? OrderDetails.id : null,
      qr_code_id: qrcode,
    };

    let res = await getUpdateOrderDetails(payload);
    if (res.status === 200) {
      getOrderDetailsData(OrderDetails);
      dispatch(addOrderDetails(res?.data));
    } else {
      setLoading(false);
    }
  };

  const syncOrderPaymentinDB = async (qrcode, restaurantid) => {
    let req = {
      restaurent_id: restaurantid,
      qr_code_id: qrcode,
    };

    await syncOrderPayemnt(req);
    updateOrderDetails(qrcode);
  };

  const syncposAndsendValuestoDB = async (qrcode, restaurantid) => {
    setLoading(true);
    let req = {
      restaurent_id: restaurantid,
      qr_code_id: qrcode,
    };
    let res = await syncPOS(req);
    if (res.status === 200) {
      syncOrderPaymentinDB(qrcode, restaurantid);
    } else {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (currentActive?.[0]?.qrcodeid && currentActive?.[0]?.restaurentid) {
  //     setLoading(true);
  //     // setTimeout(() => {
  //       syncposAndsendValuestoDB(
  //         currentActive?.[0]?.qrcodeid,
  //         currentActive?.[0]?.restaurentid
  //       );
  //     // }, 7000);
  //   }
  // }, []);

  const sendReceiptOnEmail = async () => {
    setLoadingEmail(true);
    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailID) {
      if (emailID.match(regex)) {
        let req = {
          transaction_id: transactionData?.id,
          order_id: orderDeails.id,
          email: emailID,
          orderDateTime: moment
            .unix(Number(orderDeails.order_date))
            .format("DD-MM-YYYY hh:mm:ss"),
        };

        let res = await sendEmailReceipts(req);
        if (res.status === 200) {
          setLoadingEmail(false);
          toast.info(res?.message, { theme: "colored" });
        } else {
          setLoadingEmail(false);
          toast.error(res?.response?.data?.message, { theme: "colored" });
        }
      } else {
        setLoadingEmail(false);
        toast.error("Please enter your valid email id", { theme: "colored" });
      }
    } else {
      setLoadingEmail(false);
      toast.error("Please enter email id", { theme: "colored" });
    }
    setLoadingEmail(false);
  };

  const handleFeedback = async (e) => {
    setIsFeedbackSumbitted(true);
    let req = {
      gratification_rating: e,
      order_id: orderDetails?.id,
      qr_code_id: qrCodeID?.id,
    };

    if (orderDetails?.order_status === "9") {
      await SmartFeedbackApi({ ...req, type: 3 });
    } else {
      await SmartFeedbackApi({ ...req, type: 2 });
    }
  };

  // useEffect(() => {
  //   transactionDetails();
  // }, []);

  const updateWebhook = async (payload) => {
    let req = {
      Data: payload,
    };
    await internalWebHook(req);
    transactionDetails();
    if (currentActive?.[0]?.qrcodeid && currentActive?.[0]?.restaurentid) {
      // setLoading(true);
      syncposAndsendValuestoDB(
        currentActive?.[0]?.qrcodeid,
        currentActive?.[0]?.restaurentid
      );
    }
    // navigateUser();
  };

  const paymentStatus = async (paymentId) => {
    let req = {
      Key: paymentId,
      KeyType: "PaymentId",
      restaurentid: currentActive?.[0]?.restaurentid,
    };

    let res = await getPaymentStatus(req);
    // transtion status 0 -> new transtion / 1 -> pending transation / 2 -> completed transtaion / 3 -> failed transtion
    // webhook status !== 1 ( didn't call from myfaatoorah)
    if (res?.data?.webhook_status == 1) {
      // console.log(res, "resssssssssssss");
      // setLoading(false);
      //webhook called by myFatoorah
      transactionDetails();
      if (currentActive?.[0]?.qrcodeid && currentActive?.[0]?.restaurentid) {
        // setLoading(true);
        syncposAndsendValuestoDB(
          currentActive?.[0]?.qrcodeid,
          currentActive?.[0]?.restaurentid
        );
      }
    } else {
      let requestPayload = {
        InvoiceId: res?.data?.InvoiceId,
        TransactionStatus: res?.data?.transaction_status,
      };
      updateWebhook(requestPayload);
      // setIsWebhookCalled(requestPayload);
      // setLoading(false);
      //webhook called by NodeJS
    }
  };

  useEffect(() => {
    if (location?.search) {
      let paymentId = location?.search?.split("&")?.[0]?.split("=")?.[1];
      setTimeout(() => {
        paymentStatus(paymentId);
      }, 6000);
    }
  }, []);

  if (loading) {
    return (
      <PaymentWrapper>
        <Lottie options={defaultOptions} height={320} width={320} />
        <p>Your transaction is in-progress</p>
        <h1>Please don't refresh the page!</h1>
      </PaymentWrapper>
    );
  }

  if (reloadNeeded) {
    return (
      <ReloadNeededWrapper background={SnapayLaunchBackground}>
        <BlackLayer />
        <p>Unable to fetch transaction deatils!</p>
        <h3 onClick={() => window?.location?.reload()}>Try Again! Reload</h3>
      </ReloadNeededWrapper>
    );
  }

  // if (loading) {
  //   return (
  //     <LoaderWapper themeColor={customTheme?.theme_color}>
  //       <CircularProgress color="inherit" />
  //     </LoaderWapper>
  //   );
  // }

  return (
    <PaymentSuccessfulStyle
      textColor={customTheme?.text_color}
      background={SnapayGrayBackground}
      themeColor={customTheme?.theme_color}
      buttonTextColor={customTheme?.button_text_color}
      dir={langDir?.direction}
    >
      <div className="backArrow" onClick={() => handleNavigation()}>
        <ArrowLeftIcon />
      </div>

      <div className="paymentNotification flex">
        <CheckCircleIcon /> <IntlMessage id="Snapay.SuccessFulMessage" />
      </div>
      <div className="grayBg">
        <div className="tableFont paymentNotification">
          <h2 style={{ fontWeight: "500" }}>
            {" "}
            {langDir?.direction === "ltr"
              ? order?.QrCode?.name
              : order?.QrCode?.ar_name}
          </h2>
          <h4 style={{ fontWeight: "400" }}>
            {transactionData?.type === "1" ? (
              <IntlMessage id="Snapay.SuccessFulFullyPaid" />
            ) : transactionData?.type === "2" ? (
              <IntlMessage id="Snapay.SuccessFulCustomPaid" />
            ) : transactionData?.type === "3" ? (
              <IntlMessage id="Snapay.SuccessFulPaidForYourItems" />
            ) : (
              transactionData?.type === "4" && (
                <IntlMessage id="Snapay.SuccessFulDivideEquallyPaid" />
              )
            )}
          </h4>
        </div>
        <div className="TextFont Fontsize">
          {Number(transactionData?.tip) === 0 ? (
            <>
              <IntlMessage id="Snapay.SuccessFulPaidForYourItems" />{" "}
              {Number(
                transactionData?.amount + transactionData?.service_amount
              ).toFixed(2)}{" "}
              <IntlMessage id="Snapay.QAR" />{" "}
              <IntlMessage id="Snapay.SuccessFulAmountPaidTwo" />
            </>
          ) : (
            <>
              <IntlMessage id="Snapay.SuccessFulPaidForYourItems" />{" "}
              {Number(
                transactionData?.amount + transactionData?.service_amount
              ).toFixed(2)}{" "}
              <IntlMessage id="Snapay.QAR" />{" "}
              <IntlMessage id="Snapay.SuccessFulAmountPaidTwo" />{" "}
              <IntlMessage id="Snapay.SuccessFulAmountPaidTip" />{" "}
              {Number(transactionData?.tip)?.toFixed(2)}{" "}
              <IntlMessage id="Snapay.QAR" />
            </>
          )}
          <i onClick={() => setShowYourShare(!showYourShare)} style={styleObj}>
            <ChevronDownIcon />
          </i>
        </div>
        <div className="TextFont date">
          <span>
            <IntlMessage id="Snapay.SuccessFulTransactionDate" />
          </span>
          <span>
            {moment(transactionData?.updatedAt).format("hh:mm a, DD/MM/YYYY")}
          </span>
        </div>
        {showYourShare && (
          <OrderedItemsBill transactionData={transactionData} />
        )}
        {/* <div className="TextFont leftToPayFont">QAR 90.00 is left to pay</div> */}
      </div>

      <FeedbackWrapper>
        {isFeedbackSumbitted ? (
          <div
            className="before-feedback"
            style={{
              background: `${customTheme?.theme_color}20`,
              color: customTheme?.text_color,
            }}
          >
            <h1>Thank you!</h1>
            <p>Your feedback is important to us</p>
          </div>
        ) : (
          <div className="after-feedback">
            <p>
            <IntlMessage id="Snapay.SuccessFulOverallSatisfaction"/>{" "}
              <span>*</span>
            </p>
            <Flex gap="middle" vertical>
              <Rate
                style={{ color: customTheme?.theme_color, fontSize: 30 }}
                onChange={(e) => handleFeedback(e)}
              />
            </Flex>
          </div>
        )}
      </FeedbackWrapper>

      <div className="grayBg">
        <div className="TextFont getEmail">
          <IntlMessage id="Snapay.SuccessFulGetReceipt" />
        </div>
        <input
          type="email"
          placeholder="you@example.com"
          onChange={(e) => setEmailID(e.target.value)}
        />
        <ThemeButton
          button_text_color={customTheme?.button_text_color}
          button_primary_color={customTheme?.button_primary_color}
          onClick={sendReceiptOnEmail}
        >
          {loadingEmail ? (
            <IntlMessage id="Snapay.Loading" />
          ) : (
            <IntlMessage id="Snapay.SuccessFulSave" />
          )}
        </ThemeButton>
      </div>
      {Restaurentinfo?.google_review && (
        <>
          {" "}
          <div
            className="TextFont leftToPayFont"
            style={{ textAlign: "center" }}
          >
            <IntlMessage id="Snapay.SuccessDiningExperience" />
          </div>
          <a
            href={Restaurentinfo?.google_link}
            target="_blank"
            className="googleBtn"
            rel="noreferrer"
          >
            <GoogleIcon />
            <span>
              <IntlMessage id="Snapay.SuccessGoogleReview" />
            </span>
          </a>
        </>
      )}
    </PaymentSuccessfulStyle>
  );
};

export default PaymentSuccessful;

const FeedbackWrapper = styled.div`
  width: 100%;
  margin: 20px 0;

  .after-feedback {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 600;

    p {
      text-align: center;
    }
    p > span {
      color: red;
    }
  }

  .before-feedback {
    width: 100%;
    padding: 20px 0;
    border-radius: 8px;
    position: relative;

    span {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 12px;
      font-family: "Nunito Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      padding: 5px;
      border-radius: 8px;
    }

    h1 {
      text-align: center;
      font-size: 26px;
      font-family: "Nunito Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
    }

    p {
      text-align: center;
      font-size: 18px;
      font-family: "Nunito Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
    }
  }
`;

const PaymentWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #505052;
  p {
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
  }
  h1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
`;

const BlackLayer = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
`;

const ReloadNeededWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100dvh;
  background: ${({ background }) => `url(${background})`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 38px 10px 10px 10px;
  background-size: cover;
  background-repeat: no-repeat;

  p {
    color: #fff;
    text-align: center;
    font-family: "DM Sans";
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    z-index: 99;
    text-shadow: 1px 2px 6px #000000a1;
  }

  h3 {
    margin: 20px 0;
    background: #ffffffd1;
    padding: 20px 10px;
    border-radius: 8px;
    color: red;
    text-align: center;
    font-family: "DM Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    z-index: 999;
  }
`;

const PaymentSuccessfulStyle = styled.div`
  padding: 20px;

  .backArrow {
    cursor: pointer;
  }

  .paymentNotification {
    width: 100%;
    margin: auto;
    padding: 10px;
    background: ${({ themeColor }) => themeColor};
    border-radius: 8px;
    color: ${({ buttonTextColor }) => buttonTextColor};
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.42px;
  }

  .flex {
    width: 80%;
    display: flex;
    justify-content: center;
    gap: 8px;
  }

  .grayBg {
    padding: 23px 16px;
    background: ${({ background }) => `url(${background})`};
    border-radius: 8px;
    margin-bottom: 30px;
  }
  .tableFont {
    color: ${({ buttonTextColor }) => buttonTextColor};
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.72px;
    text-align: center;
    margin-bottom: 40px;
  }

  .date {
    display: flex;
    justify-content: space-between;
    margin: 30px 0 10px 0;
    font-size: 14px;
    font-weight: 400;
  }

  .TextFont {
    color: ${({ textColor }) => textColor};
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.42px;
  }
  .Fontsize {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    i {
      cursor: pointer;
    }
  }
  .leftToPayFont {
    font-size: 14px;
    font-weight: 500;
  }

  .getEmail {
    font-size: 14px;
    font-weight: 400;
  }
  input {
    width: 100%;
    padding: 14px 13px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.6);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    margin: 10px 0;
  }
  .googleBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: ${({ themeColor }) => themeColor};
    border: ${({ themeColor }) => `1px solid ${themeColor}`};
    padding: 14px 30px;
    border-radius: 8px;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.25px;
    margin: 16px 16px 0px;
    cursor: pointer;
    text-decoration: none;
  }
`;
