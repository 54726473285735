import { createSlice } from "@reduxjs/toolkit";

export const langSlice = createSlice({
  name: "selectLang",
  initialState: {
    lang: "en",
    direction: "ltr",
  },

  //This is SelectLang reducers.

  reducers: {
    langSuccess: (state, action) => {
      if (action.payload) {
        state.lang = action.payload.lang;
        state.direction = action.payload.direction;
      } else {
        state.lang = null;
        state.direction = null;
      }
    },
  },
});

//This is Selectlang actions.

export const { langSuccess } = langSlice.actions;
export default langSlice.reducer;
