
export function AmountWithDecimalValue(x) {
  let n = Number(x).toFixed(2);
  if (n) {
    let res = n.split(".");
    if (res) {
      if (Number(res[1]) > 0) {
        return n;
      } else {
        return res[0];
      }
    }
  }
}

export const checkOrderIsFromPOS = (GetAllOrders) => {
  var flag = 0;
  if (GetAllOrders?.length > 0) {
    for (let i = 0; i < GetAllOrders.length; i++) {
      if (GetAllOrders[i].OrderItems.length > 0) {
        for (let j = 0; j < GetAllOrders[i].OrderItems.length; j++) {
          if (GetAllOrders[i].OrderItems[j].customer_name === "No customer") {
            flag++;
            return false;
          }
        }
        console.log(flag, "flagggggggggggggggg");
      } else {
        return true;
      }
    }
    if (flag === 0) {
      return true;
    }
  } else {
    return true;
  }
};

export const addCommaToNumbers = (num, service) => {
  let s = Number(service) || 0;
  let total = Number(num) + Number(s);
  let n = total.toFixed(2);
  const numberFormatter = Intl.NumberFormat("en-US");
  const formatted = numberFormatter.format(n);
  if (formatted !== "NaN") {
    if (formatted.includes(".")) {
      return formatted;
    } else {
      return `${formatted}.00`;
    }
  } else {
    return "0.00";
  }
};

export const calculateServiceCharge = (totalValue, charge) => {
  if (charge) {
    const value = (Number(totalValue) / 100) * Number(charge);
    return Number(value?.toFixed(2));
  } else {
    return 0;
  }
};

export const calculateCeiling = (value) => {
  if (value < 100) {
    return Math.ceil((value + 0.1) / 10) * 10;
  } else if (value >= 100 && value <= 400) {
    return Math.ceil((value + 0.1) / 20) * 20;
  } else {
    return Math.ceil((value + 0.1) / 50) * 50;
  }
};
