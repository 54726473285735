import { createGlobalStyle } from "styled-components"

export const PaymentOne = createGlobalStyle`



    .paymentWrapper{
        padding: 12px 20px;
        h6{
            font-family: 'Jost';
            font-style: normal;
            color: #202020;
            font-weight: 600;
            font-size: 20px;
            line-height: 124.5%;
        }

        .methods{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 18px;
        }

        @media only screen and (max-width: 580px){
            .methods{
                display:grid;
                grid-template-columns: repeat(2 , 1fr); 
            } 
        }

        button{
            font-family: 'Jost';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            color: ${(({theme})=>theme?.paymentButtonColor)};
            padding: 12px 29px;
            background: ${(({theme})=> theme?.paymentButtonBg)};
            border-radius: 6.7806px;
            border: none;
            position: absolute;
            margin-left: -60px;
            left: 50%;
            width: 130px;
            bottom: 30px;
        }
    }
`;