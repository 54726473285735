import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { GetTermAndConditionApi } from "../../services/Collection";
import { SnapaySLogo } from "../../Utils/Icons";

export default function TermAndCondition() {
  const navigate = useNavigate();
  const [term, setTerm] = useState();
  const language = useSelector((state) => state?.selectLang);

  const handleTerm = async () => {
    let res = await GetTermAndConditionApi();

    if (res?.status === 200) {
      console.log("2-000",res?.data)
      if (language?.lang === "en") {
        setTerm(res?.data?.[0]?.terms_and_condition_english);
      } else {
        setTerm(res?.data?.[0]?.terms_and_condition_arabic);
      }
    }
  };
  console.log(term, "term and condiotn");

  useEffect(() => {
    handleTerm();
  }, []);
  return (
    <TermAndConditionWrapper>
      <div className="logoDiv">
        <i onClick={() => navigate(-1)}>
          <SnapaySLogo />
        </i>
      </div>
      {/* <h1>Terms & Conditions</h1>
      <p>
        By accessing or using the services provided by <span>Snapay</span>, you
        agree to be bound by the following terms and conditions.
      </p>
      <p>
        Reservations are recommended and subject to availability. We reserve the
        right to modify or cancel reservations at our discretion, with changes
        communicated in advance. Failure to notify us of changes may result in
        fees.
      </p>

      <p>
        All orders are subject to acceptance and availability. Prices are
        inclusive of taxes, and we reserve the right to change them without
        notice. Payments must be made in the specified currency, and various
        payment methods are accepted.
      </p>
      <p>
        Cancellation policies apply to reservations and orders, with refunds
        processed based on our refund policy. Health and safety guidelines must
        be followed, and we reserve the right to refuse service to individuals
        showing symptoms of illness.
      </p>
      <p>
        Customers are responsible for communicating any food allergies or
        dietary restrictions. While we take precautions, we cannot guarantee
        allergen-free dishes.
      </p>
      <p>
        Our intellectual property, including website content, is protected.
        Reproduction without permission is prohibited.
      </p>
      <p>
        Our privacy policy outlines the collection and use of personal
        information. Users creating accounts must provide accurate information
        and are responsible for maintaining confidentiality. We reserve the
        right to update these terms at any time. Users are responsible for
        regularly reviewing changes.
      </p>
      <p>
        These terms are governed by the laws of [Your Jurisdiction], with
        disputes subject to the exclusive jurisdiction of the courts in [Your
        Jurisdiction].
      </p>
      <p>
        For further inquiries,
        <br /> contact <span>Snapay Team</span>
        <br /> at <span>XXXXXXXXX</span>.
      </p> */}
      <div
      dir={language?.direction}
        dangerouslySetInnerHTML={{ __html: term }}
      ></div>
    </TermAndConditionWrapper>
  );
}

const TermAndConditionWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .logoDiv {
    display: flex;
    justify-content: center;
  }
  h1 {
    color: #171717;
    text-align: center;
    font-family: DM Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 20px 0;
  }

  p {
    color: #171717;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 8px 0;
    span {
      // font-size: 17px;
      font-weight: 700;
      color: #66433f;
    }
  }
`;
