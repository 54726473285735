
// DEV AND STAGE
//export const aqlutstorage = "https://snapaystorage.blob.core.windows.net/";

// PRODUCTION
export const aqlutstorage = "https://aurasnapaystorage.blob.core.windows.net/";

// AQLUT PRODUCTION
// export const aqlutstorage = "https://SnapayMainStorageCDN.azureedge.net/";

export const containerProfile = "profile/";

export const containerMenu = "menu/";

export const containerItem = "item/";

export const containerSection = "section/";

export const containerMenuBranding = "menubranding/";

export const containerAdvertisment = "banners/"
