import React from "react";
import { useSelector } from "react-redux";
import "../../style/AllergiesItem.scss";
import IntlMessage from "../../Utils/IntlMessage";

const AllergiesItem = ({ Close }) => {
  const { direction, item } = useSelector(
    ({ selectLang, selectItemSlice }) => ({ ...selectLang, ...selectItemSlice })
  );
  const customTheme = useSelector((state) => state?.theme?.branding);

  return (
    <>
      {item?.allergies?.length > 0 ? (
        <section className="aller-badge-parent" dir={direction}>
          <h6 style={{ color: customTheme?.header_color }}>
            <IntlMessage id="AddItemDrawer.allegries" />
          </h6>
          <img
            onClick={Close}
            src={require("../../assets/close.png")}
            alt="close-button-icon"
            className={
              direction === "rtl" ? "center-aligned-icon" : "allergies-reclose"
            }
          />

          <div className="inner-badge">
            {item?.allergies?.map((alg, key) => {
              return (
                <div
                  key={key}
                  className="make-badge"
                  style={{
                    color: customTheme?.button_text_color,
                    background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
                  }}
                >
                  <p style={{color:"inherit"}} >{alg.name}</p>
                </div>
              );
            })}
          </div>
        </section>
      ) : (
        <div className="no-data">
          <div className="cross-button">
            <img
              onClick={Close}
              src={require("../../assets/close.png")}
              alt="close-button-icon"
              className={
                direction === "rtl"
                  ? "center-aligned-icon"
                  : "allergies-reclose"
              }
            />
          </div>
          <div>
            <p>Sorry we don't have any items for now!</p>
          </div>
        </div>
      )}
    </>
  );
};
export { AllergiesItem };
