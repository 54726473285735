import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { aqlutstorage, containerSection } from "../../Utils/ImageStorage";
import { useSelector } from "react-redux";
import SubSection from "./SubSection";
import Item from "../AddFoodList/Item";

export default function MainSection({ payload, toggleDrawer, ref }) {
  const Direction = useSelector((state) => state?.selectLang?.direction);
  console.log(payload, "payload00000000000");
  let backgroundCover = aqlutstorage + containerSection + payload?.image;
  const customTheme = useSelector((state) => state?.theme?.branding);

  const [isMainSectionOpen, setMainSectionOpen] = useState(payload?.is_maximize);

  // const checkingInner = (data) => {
  //   console.log('datadtadtadtadtda', data)
  //   if(data.id == 51){
  //     for (let i = 0; i < data.length; i++) {
       
  //     }

  //   }
  // }

  // useEffect(()=>{
  //   checkingInner(payload)
  // },[]);

  return (
    <main ref={ref} >
      <MainSectionBox
        backgroundCover={payload?.image ? backgroundCover : null}
        themeColor={customTheme?.theme_color}
        button_text_color={customTheme?.button_text_color}
        onClick={() => setMainSectionOpen(!isMainSectionOpen)}
      >
        <BlackLayer />
        {/* {payload?.image === null && (
          <PatternTheme>
            <img src={SectionPatter} alt="pattern" />
          </PatternTheme>
        )} */}
        <header style={{ color: customTheme?.button_text_color }}>
          {Direction === "ltr"
            ? payload?.section_name
            : payload?.ar_section_name}
        </header>
      </MainSectionBox>

      {isMainSectionOpen && (
        <SubSectionWrapper>
          {isMainSectionOpen && payload?.Sections?.length > 0 && (
            <section>
              {payload?.Sections?.map(
                (sub) =>
                  sub?.Items?.length > 0 && (
                    <SubSection subData={sub} toggleDrawer={toggleDrawer} />
                  )
              )}
            </section>
          )}

          {isMainSectionOpen && payload?.Items?.length > 0 && (
            <section>
              {payload?.Items?.map((item, key) => (
                    <SubSectionItemWrapper boxBackground={customTheme?.placeholder_box_color} >
                  <Item
                    identifyLastItemOfSection={
                      payload?.Items?.length - 1 === key && true
                    }
                    key={key + 3}
                    item={item}
                    toggleDrawer={() => toggleDrawer(item)}
                  />
                </SubSectionItemWrapper>
              ))}
            </section>
          )}
        </SubSectionWrapper>
      )}
    </main>
  );
}

const PatternTheme = styled.div`
  position: absolute;
  inset: 0;
  opacity: 0.2;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    transform: scale(1.2);
  }
`;

const SubSectionItemWrapper = styled.div`
  width: 100%;
  min-height: 176px;
  max-height: 100%;
  padding: 12px 16px;
  //   background:red;
  margin: 12px 0;
  box-shadow: 0 3px 8px 4px rgb(0 0 0 / 15%);
  border-radius: 8px;
  background: ${({boxBackground})=> boxBackground};
`;

const SubSectionWrapper = styled.div`
  width: 100%;
  height: auto;

  section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 24px 0 28px 0;

    .sub-section {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: space-between;
      padding: 12px 16px;
      border: 1px solid rgb(0 0 0 / 10%);
      border-radius: 8px;
      color: #575962;
      box-shadow: 0 0 5px rgb(0 0 0 / 10%);

      p {
        font-family: "Nunito Sans", sans-serif;
        font-weight: 600;
        font-size: 18px;
      }

      svg path {
        fill: #575962;
      }
    }
  }
`;

const MainSectionBox = styled.div`
  position: relative;
  min-width: 100%;
  height: 146px;
  background: ${({ backgroundCover, themeColor }) =>
    backgroundCover ? `url("${backgroundCover}")` : themeColor};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Nunito Sans", sans-serif;
  color: #ffffff;

  // @media(max-width:400px){
  //   height: 146px;
  // }

  header {
    font-weight: 700;
    font-size: 30px;
    z-index: 99;
    text-shadow: 0 0 5px #black;
  }
`;

export const BlackLayer = styled.div`
  position: absolute;
  inset: 0;
  background: rgb(0 0 0 / 25%);
  border-radius: 12px;
`;
