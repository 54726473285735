import React from "react";
import { useLottie } from "lottie-react";
import styled from "styled-components";
import scanner from "../../lotties/scanner.json";
import { Foodbgimg } from "../../Utils/Images";

const Scanner = ({ message }) => {
  const options = {
    animationData: scanner,
    loop: true,
  };
  const { View } = useLottie(options);
  return (
    <ScannerStyled bg={Foodbgimg}>
      <div className="wrap">
        {View}
        <div className="text">{message}</div>
      </div>
    </ScannerStyled>
  );
};

export default Scanner;

const ScannerStyled = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  font-weight: 600;
  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: bottom;

  .wrap {
    position: relative;
  }

  .text {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
  }
`;
