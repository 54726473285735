import { createSlice } from "@reduxjs/toolkit";

export const selectItemSlice = createSlice({
  name: "selectItem",
  initialState: {
    item: [],
    priceOption: {},
    addOn: [],
    recommendedItems: [],
    quantity: 1,
    specialNotes: "",
    totalPrice: 0,
    item_price: 0,
    addOnId: [],
    posEnable: false,
  },

  reducers: {
    addItem: (state, action) => {
      var isPOSEnable = localStorage.getItem("isPOSEnable");

      state.item = action.payload;
      state.priceOption = {};
      state.addOn = [];
      state.addOnId = [];
      state.recommendedItems = [];
      state.quantity = 1;
      state.specialNotes = "";

      if (action?.payload?.ItemPrices?.length > 1) {
        state.totalPrice = 0;
        state.item_price = 0;
      } else {
        if (isPOSEnable === "true" || isPOSEnable === true) {
          const obj = {
            id: -1,
            price: action?.payload?.ItemPrices?.[0]?.pos_item_price,
            calories: action?.payload?.ItemPrices?.[0]?.calories,
          };
          state.priceOption = obj;
          state.totalPrice = action?.payload?.ItemPrices?.[0]?.pos_item_price;
          state.item_price = action?.payload?.ItemPrices?.[0]?.pos_item_price;
        } else {
          const obj = {
            id: -1,
            price: action?.payload?.ItemPrices?.[0]?.price,
            calories: action?.payload?.ItemPrices?.[0]?.calories,
          };
          state.priceOption = obj;
          state.totalPrice = action?.payload?.ItemPrices?.[0]?.price;
          state.item_price = action?.payload?.ItemPrices?.[0]?.price;
        }
      }
    },
    removeItem: (state) => {
      state = {
        item: [],
        priceOption: {},
        addOn: [],
        recommendedItems: [],
        quantity: 1,
        specialNotes: "",
        totalPrice: 0,
        item_price: 0,
        addOnId: [],
      };
    },
    SelectPriceOption: (state, action) => {
      state.priceOption = action.payload;
      state.item_price = action.payload.price;
      let itemPrice = action.payload.price * state.quantity;
      state.addOn?.map(
        (item) =>
          (itemPrice = itemPrice + parseInt(item.price) * state?.quantity)
      );
      state.recommendedItems?.map(
        (item) => (itemPrice = itemPrice + parseInt(item.price) * item.count)
      );
      state.totalPrice = itemPrice;

    },
    //Adding add on
    SelectAddOn: (state, action) => {
      var isPOSEnable = localStorage.getItem("isPOSEnable");
      const isMatch = state.addOn?.filter((el) => el?.id === action.payload.id);

      if (isMatch?.length > 0) {
        //removing addon
        const add_on = state.addOn.filter((el) => el.id !== action.payload.id);
        state.addOn = add_on;
      } else {
        state.addOn = [...state.addOn, action.payload];
        state.addOnId = [...state.addOnId, action.payload.id];
      }

      let itemPrice =
        (state?.priceOption?.price ? state?.priceOption?.price : 0) *
        state?.quantity;
      if (isPOSEnable === "true" || isPOSEnable === true) {
        state.addOn?.map(
          (item) =>
            (itemPrice =
              itemPrice + parseInt(item.posModifierPrice) * state?.quantity)
        );
      } else {
        state.addOn?.map(
          (item) =>
            (itemPrice = itemPrice + parseInt(item.price) * state?.quantity)
        );
      }
      state.recommendedItems?.map(
        (item) => (itemPrice = itemPrice + parseInt(item.price) * item.count)
      );
      state.totalPrice = itemPrice;
  
    },
    //Removing add on value after error messasge
    ClearAddOn: (state) => {
      state.addOn = [];
      state.addOnId = [];
    },
    addRecommendedItem: (state, action) => {
      let found = false;
      state.recommendedItems.map((item) => {
        if (item.id === action.payload.id) {
          item.count = item.count + 1;
          found = true;
        }
      });

      if (!found) {
        const obj = {
          id: action.payload.id,
          count: 1,
          price: action.payload.price,
        };
        state.recommendedItems = [...state.recommendedItems, obj];
      }
      let itemPrice =
        (state?.priceOption?.price ? state?.priceOption?.price : 0) *
        state?.quantity;
      // state.addOn.length > 0 &&
        state.addOn?.map(
          (item) =>
            (itemPrice = itemPrice + (parseInt(item.price) * state?.quantity))
        );
      //Filltering and returning only not 0 count value
      state.recommendedItems = state.recommendedItems.filter(
        (item) => item.count > 0
      );
      state.recommendedItems?.map(
        (item) => (itemPrice = itemPrice + parseInt(item.price) * item.count)
      );
      state.totalPrice = itemPrice;
    },
    deleteRecommendedItem: (state, action) => {
      state.recommendedItems.map((item) => {
        if (item.id === action.payload) {
          item.count = item.count - 1;
        }
      });
      let itemPrice =
        (state?.priceOption?.price ? state?.priceOption?.price : 0) *
        state?.quantity;
      state.addOn?.map(
        (item) =>
          (itemPrice = itemPrice + parseInt(item.price) * state?.quantity)
      );
      //Filltering and returning only not 0 count value
      state.recommendedItems = state.recommendedItems.filter(
        (item) => item.count > 0
      );

      state.recommendedItems?.map(
        (item) => (itemPrice = itemPrice + parseInt(item.price) * item.count)
      );
      state.totalPrice = itemPrice;
    },
    incrementQty: (state, action) => {
      var isPOSEnable = localStorage.getItem("isPOSEnable");
      state.quantity += 1;
      let itemPrice =
        (state?.priceOption?.price ? state?.priceOption?.price : 0) *
        state?.quantity;

      state.addOn?.map(
        (item) =>
          (itemPrice =
            itemPrice +
            (isPOSEnable === "true" || isPOSEnable === true
              ? parseInt(item.posModifierPrice)
              : parseInt(item.price)) *
              state?.quantity)
      );
      state.recommendedItems?.map(
        (item) => (itemPrice = itemPrice + parseInt(item.price) * item.count)
      );
      state.totalPrice = itemPrice;
    },
    decrementQty: (state, action) => {
      var isPOSEnable = localStorage.getItem("isPOSEnable");
      if (state.quantity === 1) {
        return state;
      } else {
        state.quantity -= 1;
      }
      let itemPrice =
        (state?.priceOption?.price ? state?.priceOption?.price : 0) *
        state?.quantity;
      state.addOn?.map(
        (item) =>
          (itemPrice =
            itemPrice +
            (isPOSEnable === "true" || isPOSEnable === true
              ? parseInt(item.posModifierPrice)
              : parseInt(item.price)) *
              state?.quantity)
      );
      state.recommendedItems?.map(
        (item) => (itemPrice = itemPrice + parseInt(item.price) * item.count)
      );
      state.totalPrice = itemPrice;
    },
    specialNotes: (state, action) => {
      state.specialNotes = action.payload;
    },
  },
});

export const {
  addItem,
  removeItem,
  SelectPriceOption,
  SelectAddOn,
  addRecommendedItem,
  incrementQty,
  decrementQty,
  deleteRecommendedItem,
  specialNotes,
  ClearAddOn,
} = selectItemSlice.actions;
export default selectItemSlice.reducer;
