import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
// import PaymentFailedAnimation from "../../lotties/paymentFailedAnimation.json";
import { SnapayGrayBackground } from "../../Utils/Images";
import { ArrowLeftIcon, CheckCircleIcon, PaymentFailedIcon } from "../../Utils/Icons";
import { ThemeButton } from "../../style/SnapayGobalStyle";

export default function PaymentFailed() {
  const customTheme = useSelector((state) => state?.theme?.branding);
  const langDir = useSelector((state) => state?.selectLang);
  const order = useSelector(
    (state) => state?.orderInformationSlice?.PosSyncedOrder
  );

  const navigate = useNavigate();
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);

  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: PaymentFailedAnimation,
  // };

  return (
    <PaymentFailedWrapper
      textColor={customTheme?.text_color}
      background={SnapayGrayBackground}
      themeColor={customTheme?.theme_color}
      buttonTextColor={customTheme?.button_text_color}
      dir={langDir?.direction}
    >
      <div className="backArrow" onClick={() => navigate(`/?qrcodeid=${currentActive?.[0]?.qrcodeid}`)} >
        <ArrowLeftIcon />
      </div>

      <div className="paymentNotification flex">
        <CheckCircleIcon /> 
        Your payment was failed !
      </div>

      <div className="grayBg">
        <div className="tableFont paymentNotification">
          <h2 style={{ fontWeight: "500" }}>
            {" "}
            {langDir?.direction === "ltr"
              ? order?.QrCode?.name
              : order?.QrCode?.ar_name}
          </h2>
        </div>


        <PaymentFailedIcon />
        <h6>Payment Failed, Oops!</h6>

        <ThemeButton
          button_text_color={customTheme?.button_text_color}
          button_primary_color={customTheme?.button_primary_color}
          onClick={() => navigate(`/?qrcodeid=${currentActive?.[0]?.qrcodeid}`)}
        >
          Please try again!
        </ThemeButton>

      </div>
    </PaymentFailedWrapper>

    // <PaymentFailedWrap>
    //   <section className="upper">
    //     <div className="failedanimation">
    //       <Lottie options={defaultOptions} className="failedanimation"  />
    //     </div>
    //     <span
    //      onClick={() => navigate(`/?qrcodeid=${currentActive?.[0]?.qrcodeid}`)}>
    //       Failed</span>

    //     <p>
    //       Your transaction has failed due to some technical error, Please try
    //       again!
    //     </p>
    //   </section>

    //   <section className="lower">
    //     <p>
    //       Your payment wasn't<br/> completed.
    //     </p>

    //     <button
    //       onClick={() => navigate(`/?qrcodeid=${currentActive?.[0]?.qrcodeid}`)}
    //     >
    //       Please try again
    //     </button>
    //   </section>
    // </PaymentFailedWrap>
  );
}

// const PaymentFailedWrap = styled.div`
//   position: relative;
//   width: 100%;
//   height: 100dvh;

//   .upper {
//     width: 100%;
//     height: 70dvh;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     gap:12px;

//     .failedanimation {
//       width: 320px;
//       height: 320px;
//     }

//     p {
//       font-family: DM Sans;
//       font-size: 22px;
//       font-weight: 400;
//       line-height: 24px;
//       letter-spacing: 0.2px;
//       color: #000;
//       text-align: center;
//       padding:5px 20px;
//     }

//     span {
//       width: fit-content;
//       padding: 12px 60px;
//       background: #e20a0b;
//       border-style: none;
//       border-radius: 20px;
//       font-family: DM Sans;
//       font-size: 16px;
//       font-weight: 500;
//       line-height: 18px;
//       -webkit-letter-spacing: -0.03em;
//       -moz-letter-spacing: -0.03em;
//       -ms-letter-spacing: -0.03em;
//       -webkit-letter-spacing: 0.2px;
//       -moz-letter-spacing: 0.2px;
//       -ms-letter-spacing: 0.2px;
//       letter-spacing: 0.2px;
//       color: #ffffff;
//       text-align: center;
//       box-shadow: 0 0 5px #000;
//     }
//   }

//   .lower {
//     width: 100%;
//     height: 30dvh;
//     // background: linear-gradient(45deg, #e20a0b, #ff5959);
//     background: linear-gradient(0deg, #e20a0b 70%, #ff5959 100%);
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     gap: 20px;
//     padding: 0 20px;
//     border-top-left-radius: 16px;
//     border-top-right-radius: 16px;
//     box-shadow: 0 0 11px #696969;

//     p {
//       font-family: DM Sans;
//       font-size: 20px;
//       font-weight: 400;
//       line-height: 26px;
//       letter-spacing: 0.2px;
//       color: #fff;
//       text-align: center;
//     }

//     button {
//       width: fit-content;
//       padding: 12px 20px;
//       background: #fff;
//       border-style: none;
//       border-radius: 20px;
//       font-family: DM Sans;
//       font-size: 16px;
//       font-weight: 700;
//       line-height: 18px;
//       -webkit-letter-spacing: -0.03em;
//       -moz-letter-spacing: -0.03em;
//       -ms-letter-spacing: -0.03em;
//       letter-spacing: 0.2px;
//       color: #e20a0b;
//       text-align: center;
//       box-shadow: 0 0 5px #fff;
//     }
//   }
// `;

const BlackLayer = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
`;

const PaymentFailedWrapper = styled.div`
  padding: 20px;

  .backArrow {
    cursor: pointer;
  }

  .paymentNotification {
    width: 100%;
    margin: auto;
    padding: 10px;
    background: ${({ themeColor }) => themeColor};
    border-radius: 8px;
    color: ${({ buttonTextColor }) => buttonTextColor};
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.42px;
  }

  .flex {
    width: 80%;
    display: flex;
    justify-content: center;
    gap: 8px;
  }

  .grayBg {
    padding: 23px 16px;
    background: ${({ background }) => `url(${background})`};
    border-radius: 8px;
    margin-bottom: 30px;
    color:#66433F;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    h6 {
      color: ${({ themeColor }) => themeColor};
      font-family: "DM Sans", sans-serif;
      font-style: normal;
      line-height: normal;
      font-size:22px;
      letter-spacing: -0.42px;
    }
  }
  .tableFont {
    color: ${({ buttonTextColor }) => buttonTextColor};
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.72px;
    text-align: center;
    margin-bottom: 40px;
  }

  .date {
    display: flex;
    justify-content: space-between;
    margin: 30px 0 10px 0;
    font-size: 14px;
    font-weight: 400;
  }

  .TextFont {
    color: ${({ textColor }) => textColor};
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.42px;
  }
  .Fontsize {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    i {
      cursor: pointer;
    }
  }

`;
