import { createSlice } from "@reduxjs/toolkit";

export const avaiableItemInPosSlice = createSlice({
  name: "avaiableItemInPos",
  initialState: {
    ActiveItem: [],
  },

  //This is SelectLang reducers.

  reducers: {
    ActiveItems: (state, action) => {
      if (action.payload) {
        state.ActiveItems = action.payload;
      }
    },
  },
});

//This is Selectlang actions.

export const { ActiveItems } = avaiableItemInPosSlice.actions;
export default avaiableItemInPosSlice.reducer;
