import styled from "styled-components";

export const ThemeButton = styled.button`
  width: 100%;
  padding: 14px 30px;
  border-radius: 8px;
  border: ${({ button_primary_color }) => `1px solid ${button_primary_color}`};
  color: ${({ button_text_color }) => button_text_color};
  background: ${({ button_primary_color }) => button_primary_color};
  text-align: center;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 150% */
  letter-spacing: 0.25px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const WhiteButton = styled.button`
  width: 100%;
  padding: 14px 30px;
  border-radius: 8px;
  border: ${({ button_primary_color }) => `1px solid ${button_primary_color}`};
  color: ${({ button_primary_color, activeBtn, button_text_color }) =>
    activeBtn ? button_text_color : button_primary_color};
  background: ${({ button_text_color, activeBtn, button_primary_color }) =>
    activeBtn ? button_primary_color : button_text_color};
  text-align: center;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 150% */
  letter-spacing: 0.25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
`;

export const ActionButton = styled.div`
  width: 100%;
  height: auto;
  padding: 22px 18px;
  display: flex;
  gap: 10px;
  border-radius: 8px;
  background: ${({ background }) => `url(${background})`};
  background-size: contain;
  flex-direction: ${({ direction }) => (direction ? "column" : "row")};

  .back-button {
    text-align: center;
    color: rgb(95 90 90);
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
  }
`;

export const LoaderWapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ themeColor }) => themeColor};
`;

export const AppleLoaderWapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ themeColor }) => themeColor};
  padding: 50px 0px;
  background: #ffffff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;

  img {
    position: absolute;
    top: 30px;
    width: 120px;
  }
  p {
    font-family: "DM Sans", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 20px 0;
  }
`;
