import { createSlice } from "@reduxjs/toolkit";

export const posSlice = createSlice({
  name: "posStatus",
  initialState: {
    isEnable: false,
  },

  reducers: {
    posStatus: (state, action) => {
      if (action.payload) {
        state.isEnable = action.payload.pos_integration_status;
      }
    },
  },
});

export const { posStatus } = posSlice.actions;
export default posSlice.reducer;
